[view=newsletter], [view=newsletter_deregister] {
    
    $margin-top-mobile: 20px;
    $margin-top: 15px;
    
    .page-title {
        padding-right: 35px;
    }
    
    input[type=text], input[type=email], textarea {
        border: 0 none transparent;
        background-color: $color_c8;
        color: $color-c6;
        font-size: 17px;
        height: 30px;
        padding: 0 7px;

        &:focus {
            box-shadow: 0 0 0 0 transparent;
            border: 0 none transparent;
            outline: none;
        }
    }

    input[type="checkbox"], input[type="radio"] {
        position: absolute;
        top: 2px;
        transform: translateX(-100vw);
    }

    input[type="checkbox"] + label, input[type="radio"] + label {
        color: $color_c5;
        cursor: pointer;
        vertical-align: text-top;
        position: relative;
        line-height: 1.5;

        span {
            display: inline-block;
            width: 22px;
            height: 22px;
            margin-right: 10px;
            vertical-align: bottom;
            border: 2px solid $color_c7;
            background-color: $color_c9;
            line-height: 1;
        }

        &:hover {
            span {
                border: 2px solid $color_c5;
            }
        }
    }

    input[type="checkbox"]:focus, input[type="radio"]:focus {
        & + label {
            span {
                border: 2px solid $color_c5;
            }
        }
    }

    input[type="checkbox"]:checked + label span {
        border: 2px solid $color_c5;

        &:before {
            @include icon-styles();
            content: "\58";
            padding-left: 2px;
            font-size: 14px;
            font-weight: 700;
            vertical-align: middle;
        }
    }
    
    input[type="radio"] + label span {
        border-radius: 100%;
    }

    input[type="radio"]:checked + label span {
        border: 2px solid $color_c5;
        position: relative;

        &:before {
            content: "";
            display: block;
            width: 51%;
            height: 51%;
            background-color: $color_c5;
            border-radius: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    input[type="radio"] + label {
        cursor: pointer;
        line-height: 1.5;
    }
    
    textarea {
        margin-top: 5px;
        height: 100px;
        padding: 7px;
        width: 50%;
        min-width: 250px;
    }
    
    label + textarea {
        display: block;
        cursor: pointer;
    }
    
    select {
        border: 0 none transparent;
        background-color: $color_c8;
        color: $color-c6;
        font-size: 17px;
        height: 30px;
        padding: 0 7px;
        margin-bottom: 16px;

        &:focus {
            box-shadow: 0 0 0 0 transparent;
            border: 0 none transparent;
            outline: none;
        }
    }

    .newsletter-form {

        margin-bottom: 30px;

        .input-personal-info-container {
            display: flex;
            flex-wrap: wrap;
        }
        
        .input-personal-info {
            // Anrede
            &.input-personal-info-salutation {
                order: 1;
            }
            // Vorname
            &.input-personal-info-firstname {
                order: 3;
            }
            // Nachname
            &.input-personal-info-lastname {
                order: 4;
            }
            // E-Mail
            &.input-personal-info-email {
                order: 2;
            }
            // PLZ
            &.input-personal-info-zip {
                order: 5;
            }
            // Ort
            &.input-personal-info-city {
                order: 6;
                width: 65%;
            }
        }

        .input-personal-info input {
            margin-bottom: $margin-top-mobile;
        }
        
        .input-personal-info__note {
            display: block;
            width: 100%;
            font-size: 12px;
            text-align: right;
            margin-top: -8px;
        }

        .personal-info-label {
            font-size: 12px;
            padding-bottom: 8px;
        }

        .info-text-newsletter {
            line-height: 1.3;
            margin: $margin-top 0;
        }

        .info-text-privacy {
            border-top: 2px solid $color_c5;
            padding: 25px 0 0 0;
            margin: 30px 30px 10px 0;
            position: relative;
            
            label {
                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: 28px 1fr;
                    line-height: 1.3;
                }

                div {
                    display: inline;
                }
            }
        }

        .topic {
            margin-bottom: 15px;
            width: 250px;
            position: relative;
        }

        .form__group {
            margin-bottom: 15px;
        }

        .check-all-option {
            box-shadow: 0 0 0 0 transparent;
            border: 0 none transparent;
            outline: none;
            background-color: transparent;
            color: white;
            padding: 0;
            margin-top: 4px;
            text-decoration: underline;
            cursor: pointer;
            font-family: $font-first;
            font-size: 16px;

            &:focus {
                outline: 1px solid $color_c2;
            }
        }

        .newsletter-submit-button {
            box-shadow: 0 0 0 0 transparent;
            border: 0 none transparent;
            outline: none;
            margin-top: 15px;
            margin-bottom: 15px;
            width: 130px;
            background-color: $color_c2;
            color: $color_c1;
            padding: 9px 12px 9px;
            text-transform: uppercase;
            display: inline-block;
            position: relative;
            font-family: $font-second;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 1/32 * 1em;
            cursor: pointer;

            &:focus {
                outline: 2px solid $color_success;
            }
        }
    }

    .submit-success-message, .submit-fail-message {
        font-family: $font-first;
        font-size: 18px;
        letter-spacing: 0.5px;
        position: relative;
        margin: 25px 0;
    }

    .submit-success-message {
        color: $color_success;
    }

    .submit-fail-message {
        color: $color_warning;
    }

    .newsletter-examples-section {

        h3 {
            cursor: pointer;
        }

        .newsletter-examples-container {
            overflow: hidden;

            .newsletter-example-link {
                margin-bottom: 15px;
                color: $color-c5;
            }

        }

    }

    h3 {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 30px;
    }

    @include bp-max-medium () {

        .newsletter-form {
            padding-top: 25px;

            .input-personal-info-container {
                justify-content: space-between;
            }

            .input-personal-info {
                width: 100%;

                input {
                    width: 100%;
                    height: 35px;
                }
            }

            .input-personal-info-zip {
                width: 30%;
            }

            .newsletter-submit-button {
                padding: 10px 18px;
            }
        }
    }

    @include bp-medium () {
        
        input[type="checkbox"] + label, input[type="radio"] + label {
            line-height: inherit;
        }
        
        .page-title {
            padding-right: 0;
            font-size: 60px;
        }

        .newsletter-examples-section {
            h3 {
                cursor: default;
                
                &::after {
                    content: "";
                    display: none;
                }
            }

            .newsletter-examples-container {
                height: auto;
            }
        }

        input[type="checkbox"] + label {
            span {
                width: 19px;
                height: 19px;
            }
        }

        input[type="checkbox"]:checked + label span {
            &:before {
                padding-left: 1px;
                font-size: 13px;
                vertical-align: baseline;
            }
        }

        .newsletter-form {
            // width: 750px;

            .info-text-privacy {
                margin: 30px 65px 10px 0;
            }
            
            .input-personal-info {
                width: 50%;

                // Anrede
                &.input-personal-info-salutation {
                    order: 1;
                }
                // Vorname
                &.input-personal-info-firstname {
                    order: 2;
                }
                // Nachname
                &.input-personal-info-lastname {
                    order: 3;
                }
                // E-Mail
                &.input-personal-info-email {
                    order: 4;
                }
                // PLZ
                &.input-personal-info-zip {
                    order: 5;
                }
                // Ort
                &.input-personal-info-city {
                    order: 6;
                    width: 50%;
                }

                input {
                    width: 330px;
                    margin-bottom: $margin-top;
                }
            }
        
            .input-personal-info__note {
                width: 330px;
            }

            .info-text-newsletter {
                margin: 0 0 12px;
                font-weight: 500;
            }

            .topics-container {
                display: flex;
                flex-wrap: wrap;
            }

        }

    }

}