$c_dropdown-toggle-bg: $color-c1 !default;
$c_dropdown-toggle-bg-bright: $color-c2 !default;
$c_dropdown-color: $color-c2 !default;
$c_dropdown-color-bright: $color-c1 !default;

$dropdown-lineheight: 28px;

.badge__new {
    display: none;
    color: $color_c1;
    line-height: 1;
    font-size: 11px;
    padding: 2px 6px 3px;
    background-color: $color_c6;
    border-radius: 2px;
    position: relative;
    margin-left: 3px;
    top: -1px;
    
    transition: margin-left 220ms ease 160ms, opacity 220ms linear 160ms;

    &::before {
        content: '';
        @include triangle(left, $color_c6, 5px);
        position: absolute;
        left: -4px;
        top: 3px;
    }
}

.dropdown {
	max-width: 340px; // 261px;
	width: 100%;
	overflow: hidden;
	z-index: 100;
	position: relative;
	font-family: $font-second;
    
	&.active {
		padding-bottom: 72px;

        .badge__new {
            display: inline-block;
        }
	}

	.dropdown-body {
		overflow: hidden;
		display: none;
	}

	.dropdown-head {
		position: relative;
		height: 45px;
		z-index: 10;
		overflow: hidden;
	}

	.topic-dropdown-current-link {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		height: 45px;
		right: 45px;
		z-index: 11;
		transition: opacity 200ms linear;

		a {
			line-height: 45px;
			background-color: transparent;
			word-break: break-all;
		}
	}

	.dropdown-toggle {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;
		appearance: none;
		border: 0 none transparent;
		cursor: pointer;
		line-height: 45px;
		text-align: left;
		width: 100%;
		padding-left: 15px;
		padding-right: 45px;
		transition: background-color 200ms linear;

		&::after {
			@include icon-styles();
			content: "\76";
			right: 0;
			top: 0;
			display: block;
			line-height: inherit;
			font-size: 20px;
			position: absolute;
			z-index: 12;
			background-color: rgba(0,0,0,0.25);
			padding: 0 13px;
			width: 45px;
			height: 45px;
			line-height: 45px;

			transform: rotate(0deg);
			transition: transform 230ms ease-in-out;


		}
	}

	&.active {
		.dropdown-toggle {
			&::after {
				transform: rotate(180deg);
			}
		}
	}

	&.has-selection {

		.dropdown-head {
			&[data-topic-label] {
				&::before {
					content: attr(data-topic-label);
					line-height: 45px;
					display: inline-block;
					padding-left: 15px;
					padding-right: 45px;

				}

				.dropdown-toggle {
					width: 100%;

					span {
						display: none;
					}
				}
			}	
		}

		.dropdown-toggle {
			width: 45px;
			span {
				display: none;
			}

			&:hover {
				width: 100%;

				& + .topic-dropdown-current-link {
					opacity: 0;
					z-index: 0;
				}

				span {
					display: block;
				}
			}
		}

		.topic-dropdown-current-link {
			display: block;
		}

		&.active {
			.dropdown-toggle {
				width: 100%;

				span {
					display: block;
				}

				&::before {
					transform: translate(-100%,0);
				}
			}

			.topic-dropdown-current-link {
				opacity: 0;
				z-index: 0;
			}
		}
	}

	a {
		display: block;
		padding: 0 5px 0 15px;
		line-height: $dropdown-lineheight;
		text-decoration: none;

		&::before {
			content: '_';
		}

		&.active {}
	}


	&.dark {
		color: $c_dropdown-color;

		.dropdown-body {
			background-color: $c_dropdown-toggle-bg;
		}

		.dropdown-toggle {
			color: $c_dropdown-color;
		}

		&:not(.has-selection) {
			.dropdown-head {
				background-color: $c_dropdown-toggle-bg;
			}
		}

		&.has-selection {
			.dropdown-toggle {
				
				&:hover {
					background-color: $c_dropdown-toggle-bg;
				}
			}

			&.active {
				.dropdown-toggle {
					background-color: $c_dropdown-toggle-bg;
				}

				.dropdown-head {
					background-color: $c_dropdown-toggle-bg;
				}
			}
		}

		a {
			color: $c_dropdown-color;
		}
	}

	&.bright {
		color: $c_dropdown-color-bright;
		
		.dropdown-body {
			background-color: $c_dropdown-toggle-bg-bright;
		}

		.dropdown-toggle {
			color: $c_dropdown-color-bright;
		}

		&:not(.has-selection) {
			.dropdown-head {
				background-color: $c_dropdown-toggle-bg-bright;
			}
		}

		&.has-selection {
			.dropdown-toggle {
				
				&:hover {
					background-color: $c_dropdown-toggle-bg-bright;
				}
			}

			&.active {
				.dropdown-toggle {
					background-color: $c_dropdown-toggle-bg-bright;
				}

				.dropdown-head {
					background-color: $c_dropdown-toggle-bg-bright;
				}
			}
		}

		a {
			color: $c_dropdown-color-bright;

			&:not([data-topic-hover-bg]) {
				&:hover {
					color: $c_dropdown-toggle-bg-bright;
					background-color: $c_dropdown-color-bright;
				}
			}
		}
	}
}

[view=glossary] {
	.dropdown.has-selection {
		.topic-dropdown-current-link {
			display: block;
			.dropdown-reset {
				background-color: $color-c2;
			}
		}
		&.active {
			.topic-dropdown-current-link {
				display: none;
				.dropdown-reset {
					background-color: $color-c2;
				}
			}	
		}
	}
}

/* fix iPad topic homepage position */
@media (min-width: 768px) and (max-width: 1199px) {
    .page[view="home"] .dropdown {
       position: relative;
       top: $menu-top-height-desktop + 29px;
   	}
}

@include bp-max-medium () {
	.dropdown {
		margin: 24px auto 0;
	}
}


@include bp-medium () {
	.dropdown {
		position: absolute;
		left: 60px;
		top: $menu-top-height-desktop + 29px;
		margin-bottom: 60px;
		
		a {
			transition: background-color 280ms linear;
            line-height: $dropdown-lineheight - 2px;
		}

		&.has-selection {
			.topic-dropdown-current-link {
				display: block;

				&:hover {
					a {
						opacity: 0.75;
					}
				}
			}
		}

		&.has-selection:not(.active) {
			.dropdown-toggle {
				&::before {
					transition: transform 270ms ease-in-out;
				}
				&:hover {
					&::before {
						transform: translate(-100%,0);
					}
				}
			}
		}

		&:not(.active) {
			a {
				//background-color: $c_dropdown-toggle-bg;
			}
		}
	}

	[view=taxonomy-menu] {
		.dropdown {
			max-width: 415px;
			
			.topic-dropdown-current-link {
				font-size: 21px;
			}

		}
		&.active .dropdown {
			position: fixed;

			&.active {
				position: absolute;
			}
		}
	}

	[view=glossary], [view=search] {
		.dropdown {
			&.active {
				padding-bottom: 0;
				overflow: visible;
			}
			.dropdown-body {
				position: absolute;
				width: 100%;
				margin-bottom: 95px;
			}
		}
	} 
}

@include bp-large () {
    .dropdown {
        a {
            line-height: $dropdown-lineheight;
        }
    }
}