[view=home] {
	z-index: 90;

	.body {
		position: relative;
		z-index: 10;
	}

	.background {
		position: fixed;
		z-index: 0;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: $color-c4;
	}

	.teaser-fullscreen {
		cursor: pointer;
        position: fixed;
		z-index: 1;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;

        .arrow-right {
            display: block;
            position: fixed;
            top: 0;
            right: -20px;
            z-index: 2;
            width: 120px;
            height: 100%;
            cursor: pointer;
            background: none;
            border: none;
            outline: none;
            text-decoration: none;

            &:after {
                display: block;
                position: absolute;
                top: calc(50% - 40px);
                right: 30%;
                content: "\3e";
                @include icon-styles();
                color: $color-c2;
                font-size: 80px;
                text-shadow: 0 0 17px rgba(0, 0, 0, 0.5);
                text-decoration: none;
            }
        }

		.detail-image {
			position: absolute;
			left: 0;
			top: 0;
			
			min-width: 100%;
			min-height: 100%;

			figcaption {
				display: none;
			}

			img {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				display: block;

				@media (min-aspect-ratio: 4/3) { height: auto; width: 100%; }
				@media (max-aspect-ratio: 4/3) { height: 100%; width: auto; }
			}
		}

		.teaser-hero {

			display: none;

			figcaption {
				position: absolute;
				left: 0;
				bottom: 70px;
				color: $color-c1;
				padding: 15px 20px;
				z-index: 100;
				max-width: 320px;
				margin: 0 20px;

				&:not(:hover) {
					background-color: $color-c2;
				}

				&:hover {
					color: $color-c2;

					.section, .title {
						color: inherit;
					}
				}

				.section {
					font-family: $font-second;
					font-size: 11px;
					font-weight: 500;
					letter-spacing: 1/22 * 1em;
					margin-bottom: 7px;
				}

				.title {
					font-family: $font-first;
					font-size: 14px;
					line-height: 15px;
					letter-spacing: 1/28 * 1em;

					&::after {
						position: relative;
						margin-left: 5px;
						top: 2px;
						font-size: 15px;
					}
				}
			}
			.teaser-link {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: block;
			}
		}
	}

	.background, .teaser-fullscreen {
		&::after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: url('/assets/gfx/bg_dots_transparent.png') left top repeat transparent;
		}
	}

	// Themenliste Bilder
    .home-hover-list {
		.home-hover-item {

			figcaption {
				display: none;
			}
            
            .detail-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                img {
                    height: 100%;
                    width: 100%;
                }
            }

			display: none;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}
	}

	.statistic {
		position: relative;
		font-family: $font-second;
		font-weight: 300;
		color: $color-c1;
		font-size: 32px;
	}


	.lazy-image {
		&::before {
			display: none;
		}
	}
}


@include bp-max-medium () {
	[view=home] {
		position: absolute;
		top: 0;
		bottom: $menu-bottom-height-mobile;
		width: 100%;
		
		.statistic {
			display: none;
		}
	}
}


@include bp-small () {

	[view=home] {
		.statistic {
			position: fixed;
			left: 300px;
			top: 12px + $menu-top-height-desktop;
			
			.stat {
				white-space: nowrap;
				transition: transform 280ms ease-in-out, opacity 280ms linear;

				&:nth-child(2n+1) {
					transform: translate(120px,0);
					opacity: 0;
				}
				&:nth-child(2n) {
					transform: translate(-120px,0);
					opacity: 0;
				}
			}

			.description {
				white-space: inherit;
				max-width: calc(100% - 600px);
				margin-top: 1em;
			}
		}

		.home-hover-item, .body {
			&.active {
				.statistic {
					.stat {
						transform: translate(0px,0);
						opacity: 1;
					}
				}
			}
		}

		.body {
			&.active {
				.statistic {
					.stat {
						&:nth-child(1) { transition-delay: 400ms; }
						&:nth-child(2) { transition-delay: 460ms; }
						&:nth-child(3) { transition-delay: 520ms; }
						&:nth-child(4) { transition-delay: 580ms; }
						&:nth-child(5) { transition-delay: 640ms; }
					}
				}
			}
		}

		.home-hover-list {
			.statistic {
				// top: 12px + $menu-top-height-desktop;
				position: relative;
				z-index: 3;
			}
		}

		.teaser-fullscreen {
			.teaser-hero {

				figcaption {
					left: auto;
					right: 40px;
					bottom: 75px;
					margin: 0;
				}
			}
		}
		
		.home-hover-item {
			&.active {
				.statistic {
					.stat {
						&:nth-child(2) { transition-delay:  60ms; }
						&:nth-child(3) { transition-delay: 120ms; }
						&:nth-child(4) { transition-delay: 180ms; }
						&:nth-child(5) { transition-delay: 240ms; }
					}
				}
			}
		}
	}
}

@include bp-medium () {
	[view=home] {
		.statistic {
			margin: 0 auto;
			left: 370px;
			// top: 12px;
			font-size: 47px;
			line-height: 63px;
            filter: drop-shadow(0 0 8px rgba(255, 255, 255, .8));
		}

        .teaser-fullscreen {
            .arrow-right {
                right: 0;
            }
        }
	}
}

@include bp-large () {
	[view=home] {
		.statistic {
			left: 400px;
			font-size: 55px;
			line-height: 73px;
		}
	}
}

