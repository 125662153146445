$partner-slider-width-mobile: 200px !default;
$partner-slider-width-desktop: 240px !default;

#partner_slider {
    width: $partner-slider-width-mobile;
    
	.partner-link {
		padding: 0;
	}

	.detail-image-wrapper {
		position: relative;
		width: $partner-slider-width-mobile;
        height: 100%;
        
        display: flex;
        justify-content: center;
        
		img {
			max-width: 100%;
            max-height: 100%;
            display: block;
            width: auto;
            height: auto;
    	}
	}
}

@include bp-medium {
	#partner_slider {
        width: $partner-slider-width-desktop;
        height: $menu-top-height-desktop;

		.detail-image-wrapper {
			width: $partner-slider-width-desktop;
			height: $menu-top-height-desktop;
            
            display: inherit;
		}
	}
}
