@mixin lazyloader () {
	animation: lazyload 2s ease-in-out infinite;
	backface-visibility: hidden;
}

@mixin animation-scaleUpX () {
	animation-duration: 0.5s;
	animation-name: scaleUpX;
	animation-timing-function: ease-out;
}

@keyframes lazyload {
	0% { transform:rotate(0deg); }
	70%, 100% { transform:rotate(360deg); }
}

@keyframes scaleUpX {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

// Based on Animate.css from Daniel Eden & SCSS version by Geoff Graham
// https://github.com/geoffgraham/animate.scss/blob/master/
@keyframes animation-tada {
	0% {transform: scale(1);}
	10%, 20% {transform: scale(0.9) rotate(-3deg);}
	30%, 50%, 70%, 90% {transform: scale(1.1) rotate(3deg);}
	40%, 60%, 80% {transform: scale(1.1) rotate(-3deg);}
	100% {transform: scale(1) rotate(0);}
}

@mixin animation-tada($count: 1, $duration: 1s, $delay: 0ms, $function: ease, $fill: both, $visibility: hidden) {
	animation-name: animation-tada;
	animation-iteration-count: $count;
	animation-duration: $duration;
	animation-delay: $delay;
	animation-timing-function: $function;
	animation-fill-mode: $fill;
	backface-visibility: $visibility;
}
