$menu-logo-font-size-desktop: 36px !default;
$c-menu-bg: $color_c2 !default;
$menu-top-height-mobile: 68px !default;
$menu-bottom-height-mobile: 41px !default;
$menu-height-mobile: 41px !default;
$menu-top-height-desktop: 72px !default;
$menu-bottom-height-desktop: 35px !default;

/* Baunetz-Superheader */
#baunetz-superheader {
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
}

@media (max-width: 767px) {
    #baunetz-superheader .bn-partner:has(.slick-track:empty) {
        display: none;
    }
}

@media (min-width: 768px) {
    #baunetz-superheader {
        position: fixed;
    }    
}

#menu {
    
	.menu-mobile {
		position: fixed;
		width: 100%;
		z-index: 1000;
		background-color: $c-menu-bg;

		#logo {
			font-family: $font-second;
			font-weight: 500;
			display: inline-block;
			float: left;
			font-size: 18px;
			a {
				font-size: inherit;
				padding: 0 20px;
			}
		}

		&.menu-mobile-top {
			position: fixed;
			top: 0;
			height: $menu-top-height-mobile;
			//border-bottom: 1px solid $color-c4;
			box-shadow: 0 0 6px -2px #000;
		}

		&.menu-mobile-bottom {
			bottom: 0;
			height: $menu-bottom-height-mobile;
			border-top: 1px solid $color-c4;
			background-color: $color-c3;

			a {
				line-height: $menu-bottom-height-mobile;
			}
		}
	}

	.menu-top {
		top: 0;
		height: $menu-bottom-height-mobile;
		//border-bottom: 2px solid $c-menu-bg;
		
		#logo {
			font-family: $font-second;
			font-weight: 500;
		}
	}

	.menu-bottom {
		font-size: 0;
		bottom: 0;
		// border-top: 2px solid $c-menu-bg;
	}
	

	a {
		font-size: 14px;
		line-height: $menu-top-height-mobile;
		// color: inherit;
		display: inline-block;
		text-decoration: none;
		padding: 0 10px;
		position: relative;

		img {
			max-height: 100%;
			width: auto;
		}

		.counter {
			position: relative;
			display: inline-block;
			float: right;
			color: $color_c2;
			line-height: 1;
			font-size: 9px;
			padding: 2px 4px 3px;
			background-color: $color_c6;
			margin-left: 6px;
			margin-top: 13px;
			border-radius: 2px;		

			&::before {
				content: '';
				@include triangle(left, $color_c6, 4px);
				position: absolute;
				left: -4px;
				top: 3px;
			}

			&.updating {
				@include animation-tada();
			}

		}


		&.disabled {
			cursor: default !important;
			color: $color-c4 !important;

			.counter {
				display: none;
			}
		}
	}


	#watchlist_trigger {
		position: relative;
		cursor: pointer;

		&:not(:hover) {
			color: $color-c1;
		}

		&::after {
			@include icon-styles();
			content: "\42";
			display: block;
			font-size: 29px;
			margin-top: 5px;
		}

		&.checked {
			color: $color-c4;
			cursor: inherit;
		}

		#watchlist_disabled {
			display: none;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 50;
		}

		&.disabled {
			#watchlist_disabled {
				display: inline-block;
			}
		}
	}

	#baunetz_link {
		padding-left: 12px;
		line-height: 0;
		font-size: 0;

		img {
			height: $menu-bottom-height-mobile - 5px;
			margin-top: 2px;
		}
	}
}


@include bp-max-medium () {
    /* Baunetz-Superheader statt alten Header */
    //#menu .menu-top, 
    #menu .menu-mobile-top, 
    #menu #menu_toggle {
        display: none;
    }

	#menu {

		#menu_toggle {
			position: absolute;
			left: 0;
			text-align: center;
			width: $menu-bottom-height-mobile;
			height: $menu-bottom-height-mobile;
			background-color: $color-c3;
			transition: background-color 220ms ease;

			&::before, &::after {
				content: '';
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 50%;
				background-color: $color-c1;
				height: 4px;
				width: 16px;

				transition: transform 220ms ease-in-out, transform-origin 220ms ease, width 220ms ease, background-color 220ms ease;
			}

			&::before {
				transform-origin: 2px 2px;
				transform: translate(-2px, -6px) rotate(45deg);
			}

			&::after {
				transform-origin: 14px 2px;
				transform: translate(-14px, -6px) rotate(-45deg);
			}

			span {
				display: none;
			}
		}

		&.active {
			.menu-wrapper {
				.menu-bottom {
					transition: transform 290ms ease-out;
					transform: translate(0,0);
				}
			}

			#menu_toggle {
				background-color: $color-c1;
				color: $color-c2;

				&::before, &::after {
					background-color: $color-c2;
					width: 24px;
				}

				&::before {
					transform-origin: 12px 2px;
					transform: translate(-12px, -3px) rotate(-45deg);
				}

				&::after {
					transform-origin: 12px 2px;
					transform: translate(-12px, -3px) rotate(45deg);
				}
			}
		}

		.menu-wrapper {
			

			.menu-bottom {
				position: fixed;
				bottom: 0;
				transform: translate(0,100%);
				z-index: 999;
				padding: 20px 20px 60px + $menu-bottom-height-mobile;
				background-color: $color-c3;
				
				width: 100%;
				border-top: 1px solid $color-c4;
				border-bottom: 1px solid $color-c4;
				left: 0;
				
				background-color: $color_c3;
				
				transition: transform 290ms ease-in;

				a {
					display: block;
					font-family: $font-second;
					font-weight: 400;
					font-size: 16px;
					line-height: 25px;
				}
			}

			.menu-top {
				position: fixed;
				bottom: 0;
				top: auto;
				
				left: 0;
				right: 0;
				z-index: 1001;
				font-size: 0;

				border-left: 1px solid $color-c4;


				#logo {
					display: none;
				}

				div {
					display: inline;
				}
                
				a {
					border-right: 1px solid $color-c4;
					font-family: $font-second;
					font-size: 12px;
					font-weight: 700;
					position: relative;
					width: 33.333%;
					width: calc(100%/3);
					height: $menu-bottom-height-mobile;
					line-height: $menu-bottom-height-mobile;
					display: inline-block;
					text-align: center;
					vertical-align: middle;
					box-sizing: border-box;

					.text {
						line-height: 14px;
						vertical-align: middle;
						display: inline-block;
					}

					.counter {
						@include visuallyhidden();
					}
					//max-width: 300px;
					//margin: 12px auto;
					// line-height: 1.25;
					// box-shadow: 0 0 0 1px $color-c4;
					// outline: 1px solid $color-c3;
					// background-color: $color_c4;
				}

                .menu-co2 {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }
                
                .menu-triplet__slim {
                    font-weight: 400;
                }
			}

			.menu-top {
				.block-right {
					
				}
			}
		}


		#logo_baunetz {
			position: absolute;
			bottom: $menu-bottom-height-mobile + 10px;
			left: 20px;
			
			img {
				width: auto;
				height: 30px;
			}
		}

		#search_mobile_toggle {
			position: absolute;
			right: 0;
			bottom: 0;
			width: $menu-bottom-height-mobile;
			height: $menu-bottom-height-mobile;
			line-height: $menu-bottom-height-mobile;
			text-align: center;
			padding: 0 8px;

			&::before {
				font-size: 24px;
			}
		}

		#watchlist_trigger {
			&::after {
				display: none;
			}
			&.checked {
				color: $color-c5;
			}
		}

		&.active {
			.menu-wrapper {
				.menu-bottom {

				}
			}
		}

	}
}


@include bp-medium () {
    #menu .menu-top {
        display: none;
    }

	#menu {
		
		.menu-mobile {
			display: none;
		}

		.icon-logo {
			display: inline-block;
			float: left;
		}

		#search_field, .icon-logo {
			margin-top: 0.5 * ($menu-top-height-desktop - 55px);
		}

		.menu-bottom, .menu-top {
			position: fixed;
			width: 100%;
			z-index: 1000;

			.block-left, .block-right {
				display: inline-block;
			}

			.block-right {
				float: right;
			}

            .block-middle {
                position: absolute;
                left: calc(50% - 50px);

                a {
                    color: $color_c1;
                    font-size: 14px;
                }
            }

			.block-left {
				float: left;
			}
            
            .block-left__next {
                float: left;
            }
		}
		.menu-top {
			height: $menu-top-height-desktop;
			background-color: $c-menu-bg;
			box-shadow: 0 0 8px -2px #000;

			#logo {
				font-size: $menu-logo-font-size-desktop;
				line-height: $menu-top-height-desktop;
				
				a {
					font-size: inherit;
					line-height: inherit;
					padding-left: 23px;
				}
			}

			.block-left {
				position: absolute;
				left: 0;
				top: 0;
				right: 460px;
				height: 100%;
			}
		}
		.menu-bottom {
			height: $menu-bottom-height-desktop;
			background-color: $c-menu-bg;
			box-shadow: 0 0 6px -3px #000;

			a {
				line-height: $menu-bottom-height-desktop;
				color: $color_c6;
				font-size: 12px;
				letter-spacing: 1px;
				text-transform: uppercase;
				font-family: $font-second;
				font-weight: 700;
			}
		}

		#baunetz_link {
		
			img {
				height: $menu-bottom-height-desktop - 4px;
			}
		}

		.menu-triplet {
			display: inline-block;
			position: relative;
			width: 109px;
			height: 95px;
			padding: 5px 4px 4px;
			border-left: 2px solid $color_c4;
			border-right: 2px solid $color_c4;

			a {
				display: block;
				line-height: 43px;
				padding: 0 2px;
				font-family: $font-second;
				font-weight: 500;
				font-size: 10px;
				letter-spacing: 1/20 * 1em;

				& + a {
					border-top: 1px dotted $color_c1;
				}
			}
		}

		#watchlist_trigger {
			position: relative;
			width: 100px;
			height: 95px;
			text-align: center;
			padding: 18px 25px;
			font-size: 9px;
			line-height: 11px;
			letter-spacing: 1/18 * 1em;	
			font-family: $font-second;
			font-weight: 700;

			&.active {
				transition: color 120ms linear;
			}
		}

        #menu-newsletter {
            position: relative;
            width: 100px;
            height: 95px;
            text-align: center;
            padding: 18px;
            line-height: 1.1;
            
            span {
                font-size: 10px;
                letter-spacing: 1/20 * 1em;
                font-family: $font-second;
                font-weight: 500;
            }
        }
                
        .menu-triplet, #watchlist_trigger {
            display: none;
        }
        
	}
}

@include bp-large () {
	#menu {
		.menu-top {

			.block-left {
				right: 700px;
			}
		}
        
        .menu-triplet, #watchlist_trigger {
            display: inline-block;
        }
	}
}