#content {
	//padding: $menu-height-mobile 0 0;
}

[role=main] {
	&::before {
		content: '';
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 0;
		background-color: transparent;
		opacity: 0;
		z-index: 9999;
		transition: height 40ms linear 200ms, opacity 200ms linear;
	}

	&.loading {
		&::before {
			height: 100%;
			//opacity: 0;
			//transition: opacity 200ms linear;
		}
	}	
}

//Workaround fuer FF Print Bug (weisse Seite ab role=main)
@media print {
    [role=main] {
    	&::before {
    		content: none !important;
    		position: static !important;
    	}
    }
}

@include bp-max-medium () {
	[role=main] {
		position: relative;
		height: 100%;

		&.loading {
			width: 100%;
			overflow-x: hidden;
			overflow-y: visible;
		}
	}
	#content {
		// padding-top: $menu-height-mobile;
	}
}

@include bp-medium () {
	[role=main] {
		
	}
	
	#content {
		// padding: $menu-top-height-desktop 0 $menu-bottom-height-desktop;
	}
	.page {
		position: absolute;
		top: 0;
		left: 0;
		min-height: 100%;
		width: 100%;
		z-index: 200;
		padding: $menu-top-height-desktop 0 $menu-bottom-height-desktop;
		background-color: $color-c1;
		color: $color-c2;
	}
}