html {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

body {
    background-color: #000;
}

*, *:before, *:after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

template {
    display: none;
}

.visibility-hidden {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
}

.meta-ajax {
    display: none;
}

.button {
    box-shadow: 0 0 0 0 transparent;
    border: 0 none transparent;
    outline: none;
    margin-top: 50px;
    margin-bottom: 15px;
    width: 130px;
    background-color: $color_c1;
    color: $color_c2;
    padding: 9px 12px 9px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    font-family: $font-second;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1/32 * 1em;
    cursor: pointer;

    &:focus {
        outline: 1px solid $color_success;
    }
    
    &.invert {
        background-color: $color_c2;
        color: $color_c1;
    }
}

.screenreader {
    @include visuallyhidden();
}

.jsPageDot {
    position: fixed;
    top: 100vh;
    left: 100vw;
    z-index: 1;
    pointer-events: none;
    opacity: 0.1;
}

@include bp-max-medium {

    html, body {
        height: 100%;
    }

    .button {
        padding: 10px 18px;
    }
    
    .hide-for-mobile {
        display: none !important;
    }
}

@include bp-medium {
    body {
        background-color: #000;
    }
    .show-for-mobile {
        display: none !important;
    }
    .hide-for-mobile {
        display: inherit;
    }
}

@include bp-medium () {

}

//Nur zum Drucken sichtbar
@media not print {
    .print-only {
        display: none !important;
    }
}

@media print {
    .print-only {
        display: block;
    }
}