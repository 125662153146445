.external-link-list, .download-link-list {
	li {
		padding-top: 3px;
		padding-bottom: 4px;
	}
	a {
		display: inline-block;
		font-size: 14px;
		letter-spacing: 1/14 * 1em;
		line-height: 26px;
		text-decoration: none;
		font-family: $font-second;
		font-weight: 500;

		&::after {
			content: "\2192";
			@include icon-styles();
			position: relative;
			margin-left: 0.5em;
			display: inline-block;
			top: 1px;
		}
	}
}

.download-link-list {
	a {	
		&::after {
			content: "\2193";
		}
	}
}