#welcome {
    display: none;
}

@include bp-medium () {

    #welcome {
        display: block;
    }

    .welcome {
        position: fixed;
        left: -3px;
        bottom: 100px;
        z-index: 10;

        &.hidden-when-closed {
            display: none;
        }

        .text-bubble {
            box-shadow: 0 0 6px -2px #000;
            position: absolute;
            left: 15px;
            bottom: 20px;
            width: 0;
            height: 0;
            border-radius: 700px;
            display: block;
            z-index: 4;
            overflow: hidden;

            .bubble-content {
                position: absolute;
                display: block;
                width: 100%;
                top: 50%;
                transform: translate(0, -50%);
                color: white;
                font-size: 22px;
                line-height: 1.5;
                font-family: $font-second;
                text-align: center;
                padding: 20px;
                opacity: 0;
                text-decoration: none;

                .title {
                    font-size: 36px;
                }
            }
        }

        .large-bubble {
            position: absolute;
            left: 35px;
            bottom: 35px;
            width: 60px;
            height: 60px;
            border-radius: 60px;
        }

        .small-bubble {
            position: absolute;
            left: 10px;
            bottom: 10px;
            width: 30px;
            height: 30px;
            border-radius: 30px;
        }

        .close-bubble {
            position: absolute;
            left: 745px;
            bottom: 445px;
            width: 0;
            height: 0;
            border-radius: 12px;
            cursor: pointer;
            display: none;
            z-index: 5;
        }

        .icon-close:before {
            font-size: 11px;
            font-weight: 700;
            margin-left: 6px;
            vertical-align: text-bottom;
            color: white;
        }
    }

    .welcome-placeholder {
        position: fixed;
        bottom: 100px;
        left: -3px;
        z-index: 11;
        width: 15px;
        height: 15px;
        border-radius: 8px;
        display: none;
    }
}