.share-block {

	position: relative;
	font-size: 0;
	line-height: 0;

	.share-link {
		position: relative;
		display: inline-block;
		width: 32px;
		text-align: center;
		text-decoration: none;

		&::before {
			display: inline-block;
			font-size: 23px;
		}

		span {
			position: absolute;
			bottom: 175%;
			left: 50%;
			
			font-size: 12px;
			line-height: 19px;
			padding: 4px 6px 3px;
			white-space: nowrap;
			background-color: $color_c9;
			color: white;
			display: block;
			transform: translate(-50%, 0);
			transition: bottom 180ms ease;
		}

		&:hover {
			span {
				bottom: 125%;
			}
		}

		&:not(:hover) {
			span {
				@include visuallyhidden();
			}
		}
	}

	&.disabled {

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $color-c1;
			opacity: 0.5;
		}
	}

	p {
		font-size: 0;
	}

	.head {
		display: block;
		font-size: 11px;
		line-height: 16px;
		position: absolute;
		white-space: nowrap;
    	top: -16px;
	}

	&.dark {
		.share-link {
			width: 38px;

			&::before {
				background-color: $color-c9;
				color: $color-c4;
				line-height: 32px;
				width: 38px;
			}

			&:nth-of-type(2n) {
				&::before {
					background-color: $color-c8;
				}
			}
		}
	}

	&.big {
		.share-link {
			height: 42px;
			width: 42px;
			
			&::before {
				line-height: 42px;
				width: 42px;
			}
		}
	}
}

@include bp-medium () {
	
	
}