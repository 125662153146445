$layer-width-medium: 472px !default;
$layer-width-large: 740px !default;

[view="glossary-layer"] {
    display: none;
    font-family: $font-first;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: $color-c1;

    & > .panel {
        & > .wrapper {
            background-color: $color-c2;
        }
    }

    & ::selection {
        color: white;
        background-color: black;
    }
}

@include bp-medium () {
    [view="glossary-layer"] {
        display: block;

        & > .panel {
            max-width: $layer-width-medium;
            margin-left: auto;
            margin-right: auto;
        }

        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 11;

        &.active {
            position: relative;
            top: 0;
            bottom: auto;
            overflow-y: visible;

            &::after {
                content: '';
                display: table;
                width: 100%;
                position: relative;
            }
        }

        & > .panel {
            position: absolute;
            top: $menu-top-height-desktop + 29px;
            left: 0;
            border-width: 1px;
            border-style: solid;

            background-color: $color-c3;
            box-shadow: 0 0 6px -3px #000;
            margin-bottom: 240px;

            & > .wrapper {
                width: $layer-width-medium - 2px; // 2 times border-width 1px 
                padding: 30px 0;
            }

            [view=article] {
                position: static;
            }

            .btn-close-panel {
                position: absolute;
                z-index: 2;
                right: 10px;
                top: 10px;
                cursor: pointer;
                text-decoration: none;

                &::before {
                    position: relative;
                    @include icon-styles();
                    content: "\58";

                    display: block;
                    font-size: 26px;
                    text-align: center;
                    height: 26px;
                    width: 26px;
                    line-height: 26px;

                    transform: rotate(0deg);
                    transition: transform 230ms ease-in-out;
                }
            }

            [article-module="textblock"], [article-module="headline"] {
                hyphens: auto;
            }

            // adjust gallery
            [article-module=gallery] {
                padding-top: 0;
                padding-bottom: 20px;

                .detail-image-wrapper {
                    height: 435px !important;
                    
                    img {
                        max-height: 400px;
                    }
                }
            }
        }
    }
}
