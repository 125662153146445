// Icon Styles

@font-face {
    font-family: 'bnw-icons';
    src: url('/assets/fonts/bnw-icons.ttf?o6c6lu') format('truetype'),
    url('/assets/fonts/bnw-icons.woff?o6c6lu') format('woff'),
    url('/assets/fonts/bnw-icons.svg?o6c6lu#bnw-icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@mixin icon-styles() {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'bnw-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
	&::before {
		@include icon-styles();
	}
}

[class^="iconafter-"], [class*=" iconafter-"] {
	&::after {
		@include icon-styles();
	}
}

.icon-print:before, .iconafter-print::after {
  content: "\44";
}
.icon-glossary:before {
  content: "\3f";
}
.icon-zoom:before {
  content: "\2b";
}
.icon-fullscreen:before {
  content: "\46";
}
.icon-play:before {
  content: "\70";
}
.icon-architect_profile:before, .iconafter-architect_profile::after {
  content: "\e901";
}
.icon-arrow_down:before {
  content: "\76";
}
.icon-arrow_left:before {
  content: "\3c";
}
.icon-arrow_right:before, .iconafter-arrow_right:after {
  content: "\3e";
}
.icon-arrow2_down:before {
  content: "\2193";
}
.icon-arrow2_right:before, .iconafter-arrow2_right:after {
  content: "\2192";
}
.icon-bookmark:before, .iconafter-bookmark::after {
  content: "\42";
}
.icon-close:before, .iconafter-close::after {
  content: "\58";
}
.icon-cookie_arrow_down:before {
  content: "\e909";
}
.icon-cookie_arrow_right:before {
  content: "\e90a";
}
.icon-map_location:before {
  content: "\e90b";
}
.icon-search:before {
  content: "\73";
}
.icon-social_clipboard:before {
  content: "\63";
}
.icon-social_facebook:before {
  content: "\66";
}
.icon-social_mail:before {
  content: "\6d";
}
.icon-social_twitter:before {
  content: "\74";
}
.icon-social_linkedin:before {
  content: "\eaca";
}
