.pagination {
	margin-bottom: 37px;

	p.pagination-info {
		font-size: 14px;
		line-height: 18px;
	}

	.prev, .next {
		text-decoration: none;

		&::after, &::before {
			font-size: 12px;
		}

		&::before {
			margin-right: 5px;
		}

		&::after {
			margin-left: 5px;
		}
	}

	.prev + .next {
		&::before {
			content: '|';
			margin: 0 6px;
			font-size: inherit;
		}
	}
}