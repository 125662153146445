[view=imprint] {
	.image-wall {
		background-color: $color-c2;
		padding-top: 20px;
		margin-top: 11px;
		// padding-bottom: 20px;
	}

	.sponsor-image {
		margin-bottom: 16px;
		text-align: center;
	}

	.page-body {
		> div:not(.image-wall) {
			font-size: 16px;
			line-height: 26px;
			//max-width: 690px;

            p {
                max-width: none;
            }
		}
	}
}