// ===================================================
// Breakpoints
// ===================================================
$bp-small: 720px;
$bp-medium: 1200px;
$bp-large: 1600px;
$bp-xlarge: 2200px;


// ===================================================
// Colors
// ===================================================

$color_c1: #000;
$color_c2: #FFF;
$color_c3: #F8F8F8;
$color_c4: #EFEFEF;
$color_c5: #c3c3c3;
$color_c6: #929292;
$color_c7: #626262;
$color_c8: #3B3B3B;
$color_c9: #313131;

$color_success: #91BE4E;
$color_warning: #CF4343;

// ===================================================
// Fonts
// ===================================================

$font-first: 'Roboto', sans-serif;
$font-second: 'Roboto Mono', monospace;


// ===================================================
// Menu
// ===================================================

$menu-height-mobile: 41px;
$menu-top-height-mobile: 144px;
$menu-top-height-tablet: 68px; // ab 768
$menu-bottom-height-mobile: 41px;
$menu-top-height-desktop: 72px;
$menu-bottom-height-desktop: 35px;
$menu-logo-font-size-desktop: 30px;


// ===================================================
// Menu
// ===================================================

$article-width-medium: 522px;
$article-width-large: 740px;
$article-width-print: 700px;


// ===================================================
// Search
// ===================================================

$search-font-size-desktop: $menu-logo-font-size-desktop;


// INLINE IMAGE
$gfx-text-divider: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAABAgMAAACqF8bMAAAAA3NCSVQICAjb4U/gAAAACVBMVEUMDAz///+FhYU5Jc2UAAAACXBIWXMAAAsSAAALEgHS3X78AAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABh0RVh0Q3JlYXRpb24gVGltZQAyNi4wOS4yMDE2GI6brwAAAA1JREFUCJljmBqawAAABBgBS9w5LqUAAAAASUVORK5CYII=');

