.dachkult {
    
    .link-selection span {
        display: inline-block;
        width: 10px;
        border-bottom: 1px solid $color-c1;
        height: 4px;
        margin: 0 0 4px 2px;
        
        &:after {
            content: "";
            width: 7px;
            height: 7px;
            border-right: 1px solid $color-c1;
            border-bottom: 1px solid $color-c1;
            transform: rotate(-45deg);
            display: inline-block;
            float: left;
            margin-left: 4px;
        }
    }
    
    .menu-mobile-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        
        h1 {
            font-size: 20px;
        }
        
        .menu-newsletter {
            position: relative;
            width: 100px;
            height: 60px;
            
            text-align: center;
            padding: 0 !important;
            line-height: 1 !important;
            
            img {
                margin-top: 6px;
            }
            
            span {
                font-size: 10px;
                letter-spacing: 1/20 * 1em;
                font-family: $font-second;
                font-weight: 500;
            }
        }
    }

    .subnav {
        header {
            color: $color_c1;
            font-size: 30px;
            display: block;
            padding: 28px 32px 0;
            
            a {
                text-decoration: none;
            }
        }
    }
}

[view=dachkult] {
    color: $color-c1;
    background: $color-c2;
    width: 100%;
    padding-bottom: 85px;
    
    .teaser-lists {
        width: 100%;
        padding: 0 32px;

        .teaser-list {
            background: $color-c2;
            color: $color-c1;
            padding: 30px 0;
        }
    }

    .teaser-item {
        text-decoration: none;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-bottom: 35px;

        &:last-child {
            margin-bottom: 0;
        }

        h2 {
            font-size: 20px;
            color: $color_c7;
            margin-bottom: 6px;
        }

        img {
            align-self: flex-start;
            width: 33%;
            margin-right: 10px;
            max-width: 182px;
            image-rendering: -webkit-optimize-contrast;
        }
    }

    .teaser-item-selection {
        font-size: 13px;
        font-weight: 700;
        color: $color-c1;
        margin-bottom: 6px;
    }

    .teaser-item-text {
        font-size: 15px;
        color: $color_c7;
        line-height: 1.35;
        flex: 1;
    }

    .link-selection {
        display: block;
        background: $color-c2;
        color: $color-c1;
        padding: 10px 0;
        box-shadow: 0 0 6px -3px #000;
        width: 315px;
        margin: 25px auto 0;
        text-decoration: none;
        text-align: center;
    }
    
    /* mobile navigation */
    #taxonomy_menu {
        position: relative;
        width: 100%;
        padding: $menu-top-height-mobile 0 0;
        overflow-x: hidden;
        overflow-y: visible;
        border-bottom: 2px solid $color_c1;

        @media (min-width: 768px) {
            padding: $menu-top-height-tablet 0 0;
        }

        .menu-block {
            overflow: hidden;
            
            .taxonomy-list {
                position: fixed;
                transform: translate(100%, 0);
                top: -5px;
                left: 0;
                width: 100%;
                min-height: 100%;
                z-index: 50;

                a {
                    font-size: 14px;
                    line-height: 28px;

                    &::before {
                        content: '_';
                    }
                }
            }

            &.active {
                .taxonomy-list {
                    position: relative;
                    transform: translate(0%, 0);
                    transition: transform 260ms ease;
                }
            }
        }

        .glossary-bar {
            padding: 0;
            border-top: 1px solid $color-c5;
        }
    }    
}

@include bp-medium() {
    .dachkult {
    
        .menu-top {
            .block-center {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 30px;

                h1 {
                    font-family: $font-first;
                    font-weight: 700;
                    font-size: 36px;
                }
            }
        }

        .subnav {
            width: 100%;
            padding: 32px;
            color: $color-c2;
            display: flex;
            flex-flow: row nowrap;
            font-size: 16px;

            a {
                text-decoration: none;
                font-weight: 700;

                &:hover, &.active {
                    text-decoration: underline;
                    text-underline-position: under;
                }
            }
            
            header {
                color: $color-c2;
                background: $color-c1;
                padding: 0 0 0 30px;
                margin-right: 10px;
                width: 265px;
                display: flex;
                flex-flow: column;
                justify-content: center;
                
                a {
                    font-size: 16px;
                }
            }

            section {
                background: $color_c7;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-around;
                width: 100%;

                a {
                    display: block;
                    padding: 14px 0;
                    text-align: center;
                    width: 100%;
                    position: relative;
                }
            }
        }

        .teaser-lists {
            width: 100%;
            padding: 0 32px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;

            .teaser-list {
                width: 49%;
                background: $color-c2;
                color: $color-c1;
                padding: 30px;
                box-shadow: 0 0 6px -3px #000;
            }
    
            .teaser-item {
                h2 {
                    margin-bottom: 12px;
                }
            }
            
            .teaser-item-selection {
                margin-bottom: 12px;
            }
        }
    }

    [view=dachkult] {
        background: url('/assets/gfx/bg_dots_transparent.png') left top repeat $color-c2;
    }
}