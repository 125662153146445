.has-tooltip {
	position: relative;

	.tooltip {
		position: absolute;
		bottom: 175%;
		left: 50%;
		
		font-size: 12px;
		line-height: 19px;
		padding: 4px 6px 3px;
		white-space: nowrap;
		background-color: $color_c9;
		color: white;
		display: block;
		transform: translate(-50%, 0);
		transition: bottom 180ms ease;
	}

	&:hover {
		span {
			bottom: 125%;
		}
	}

	&:not(:hover) {
		span {
			@include visuallyhidden();
		}
	}
}