[view=glossary] {
    
    .page-sub-text {
        display: none;
    }
    
	.content {
		position: relative;
	}
	
	.dropdown {
		position: relative;
		left: 0;
		top: 0;
		margin-top: 20px;
		margin-bottom: 28px;

		a {
			color: $color-c1;
		}

		.dropdown-toggle, .dropdown-body {
			background-color: $color-c2;
			color: $color-c1;
		}

		&.has-selection {
			&.active {
				.dropdown-toggle {
					background-color: $color-c2;
				}
			}
		}
	}

	.glossary-list {

		padding-bottom: 95px;
		
		.glossary-list-head {
			margin-bottom: 18px;
		}

		a {
			display: inline-block;
			font-size: 16px;
			line-height: 29px;
			text-decoration: none;
			position: relative;

			&::after {
				content: '';
				display: block;
				width: 0;
				height: 2px;
				position: absolute;
				left: 0;
				bottom: -1px;
				//transition: width 250ms ease-out;
				background-color: $color-c2;
			}

			&.active {
				// z-index: 300;
				&::after {
					width: 100%;
				}
			}
		}
	}

	[view=article] {
		.btn-close-article {
			position: absolute;
			right: 10px;
			top: 10px;
			text-decoration: none;
            padding: 12px;

			&::before {
				position: relative;
				@include icon-styles();
				content: "\58";
				
				display: block;
				font-size: 26px;
				text-align: center;
				height: 26px;
				width: 26px;
				line-height: 26px;

				transform: rotate(0deg);
				transition: transform 230ms ease-in-out;
			}

		}

		[article-module="textblock"], [article-module="headline"], [article-module="related_article"], [article-module=related_tips] {
			hyphens: auto;
		}
	}
}

[article-module="topic-info"] {
    padding: 0 15px;
    margin-bottom: 0;
    
    a {
        font-size: 18px;
        text-decoration: none;
        display: inline-block;
        position: relative;
    }
}

.alphabetic-selector {
	margin: 30px 0;
	
	ol {
		display: block;
		table-layout: fixed;
		font-size: 0;

		width: 100%;

		li {
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			width: 20%;

			&:nth-of-type(2n+1) {
				a {
					background-color: $color_c8;
				}
			}

			&:nth-of-type(2n) {
				a {
					background-color: $color_c9;
				}
			}


			&.active {
				
				a {
					font-size: 20px;
					font-weight: 700;
					z-index: 70;
					box-shadow: 0 0 13px -2px black;
				}
			}
		}
	}

	a {
		font-family: $font-second;

		position: relative;
		display: block;
		height: 34px;
		line-height: 34px;
		font-size: 16px;
		color: $color-c2;
		background-color: $color-c4;
		text-decoration: none;
		vertical-align: middle;

		transition: height 120ms ease-out, line-height 120ms ease-out;
	}
}

@include bp-max-medium {
	[view=glossary] {
        
		.content {
			position: initial;
		}
		
		.page-glossary {
			padding-left: 20px;
			padding-right: 20px;
		}

		[view=article] {
			position: absolute;
			top: 153px;
			padding-top: 30px;
			left: 10px;
			right: 10px;
			padding-bottom: $menu-bottom-height-mobile;
			box-shadow: 0 0 7px 0 #000;
			z-index: 100;
		}
	}

	.alphabetic-selector {
		margin: 25px 0 0 0;
        
		ol {
			overflow-y: hidden;
			overflow-x: scroll;
			-webkit-overflow-scrolling: touch;

			white-space: nowrap;

			li {
				width: 48px;
                padding: 0;
                margin-right: 8px;
                
                &.active a {
                    line-height: 2.3;
                }
                
                a {
                    height: 48px;
                    line-height: 3;
                }
			}
		}
	}
}

@include bp-medium () {
	
	[view=glossary] {
		padding-bottom: 0;
        
        [view=article] {
            margin-top: -72px;

            [article-module="topic-info"] {
                padding: 0 30px;
            }
        }
        
        .page-sub-text {
            display: block;
        }

		.dropdown {
			a {
				&:hover {
					color: $color-c2;
				}

				&.dropdown-reset {
					&:hover {
						background-color: $color-c1;
					}
				}
			}

			&.has-selection {
				.dropdown-toggle {
					&:hover {
						background-color: $color-c2;
					}
				}
			}
		}

		.glossary-list {
			a {
				&:hover {
					&::after {
						width: 100%;
					}
				}
			}
		}
	}

	.alphabetic-selector {
		margin: 30px 0;
		
		ol {
			display: table;
			table-layout: fixed;

			height: 50px;

			li {
				display: table-cell;
				width: auto;
				padding-left: 0;
				padding-right: 0;

				&:nth-of-type(2n+1) {
					a {
						background-color: $color_c8;
					}
				}
				&:nth-of-type(2n) {
					a {
						background-color: $color_c9;
					}
				}

				&.active, &:hover {
					
					a {
						z-index: 70;
						font-size: 16px;
						font-weight: 400;
						height: 50px;
						line-height: 50px;
						box-shadow: 0 0 13px -2px black;
					}
				}

				&:hover {
					a {
						z-index: 72;
					}
				}
			}
		}

		a {
			font-family: $font-second;

			position: relative;
			display: block;
			height: 34px;
			line-height: 34px;
			color: $color-c2;
			background-color: $color-c4;
			text-decoration: none;
			vertical-align: middle;

			transition: height 120ms ease-out, line-height 120ms ease-out;
		}
	}
}


@include bp-large () {
	
	[view=glossary] {
		.glossary-list {
			a {
				font-size: 22px;
			}
		}
	}
}