#search_field {
	// width: 500px;
	display: inline-block;

	.searchfield-container {
		position: relative;
		left: -14px;
		display: table;
		width: 100%;
	}

	label {
		display: table-cell;
		vertical-align: middle;
		font-size: $search-font-size-desktop;

		font-family: $font-second;
		font-weight: 400;
		
		.underscore {
			position: relative;
			z-index: 1;
			left: 10px;
		}
	}

	input {
		border: 0 none transparent;
		line-height: 55px;
		background-color: $color-c4;
        color: $color_c1;
		font-size: $search-font-size-desktop;
		font-weight: 400;
		width: 100%;
		height: 55px;
		font-family: $font-second;
		border-radius: 0;

		&#searchfield, &.tt-hint {
			padding-left: 8px;
		}

		&:focus,&:invalid {
			box-shadow: 0 0 0 0 transparent;
			border: 0 none transparent;
			outline: none;
		}
	}

	.tt-hint {
		color: $color_c6;
	}

	.submit-search-btn {
		border: 0 none transparent;
		height: 55px;
		width: 55px;
		background-color: $color-c4;
		vertical-align: middle;
		// transition: font-size .15s ease;
		display: table-cell;

	}

	.icon-search {
		line-height: 40px;
		font-size: 30px;

		&:hover, &:focus, &:active {
			outline: none;
			cursor: pointer;
			font-size: 32px;
		}

		&::before {
			position: relative;
			top: 2px;
		}

		span {
			@include visuallyhidden();
		}
	}

	.twitter-typeahead {
		vertical-align: top;
		display: table-cell !important;
		width: 100%;
	}

	.tt-menu {
		margin-top: 20px;
		padding: 15px 0 10px 0;
		background-color: $color-c4;
		white-space: nowrap;
		cursor: default;
		transition: transform .22s ease;

		&::before {
			content: "";
			@include triangle (top, $color-c4, 10px);
			position: absolute;
			top: -20px;
			left: 10px;
			height: 20px;
		}
	}

	.tt-selectable {
		padding: 3px 20px 1px 10px;

		&:hover, &.tt-cursor {
			background-color: $color_c5;
		}
	}
}

#search_mobile_toggle {
	&::before {
		float: right;
		display: inline-block;
		line-height: inherit;
		font-size: 20px;
	}
}

/*.blinking {
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from, to {
	color: transparent;
  }
  50% {
	color: black;
  }
}*/


@include bp-max-medium () {
	#menu .menu-wrapper #search_field {
		display: none;

		&.active {
			display: block;
			position: fixed;
			top: $menu-top-height-mobile;
			bottom: $menu-bottom-height-mobile;
			left: 0;
			width: 100%;
			color: white;
			background-color: black;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			
			padding:35px 0 95px;

            @media (min-width: 768px) {
                top: $menu-top-height-tablet;
            }

			.page-title {
				font-size: 38px;
				line-height: 1;
				font-weight: 300;
				padding: 0 20px;
				margin-bottom: 40px;
			}

			.icon-close {
				position: absolute;
				top: 35px;
				right: 20px;
				font-size: 35px;
				color: white;
				padding: 0;
				border: none;
				background-color: black;

				&:hover, &:focus, &:active {
					outline: none;
				}
			}

			.searchfield-container {
				position: static;
				display: table;
				padding: 0 20px;
			}

			label {
				display: none;
			}
			
			input {
				height: 40px;
				line-height: 40px;
				color: white;
				font-size: 17px;
				font-weight: 500;
				padding: 0 10px;
				margin: 0;
				font-family: $font-second;
			}

			.tt-hint {
				background-color: $color-c8 !important;
				color: $color-c5;
			}
			
			.submit-search-btn {
				margin-left: 20px;
				height: 40px;
				width: auto;
				font-size: 17px;
				font-weight: 500;
				text-transform: uppercase;
				font-family: $font-second;
				padding: 0 15px;

				&:hover, &:focus, &:active {
					outline: none;
				}
			}

			.tt-menu {
				background-color: black;
				font-family: $font-second;
				font-size: 17px;
				margin-top: 0;
				padding-top: 20px;

				&::before {
					display: none;
				}
			}

			.tt-selectable {
				display: block;
				padding: 5px 20px 2px 10px;
			}

		}
	}

}

@include bp-medium {
	#search_field {
		position: absolute;
		left: 284px;
		right: 40px;
		top: 0;
		height: 100%;
	}
	#menu #search_mobile_toggle {
		display: none;
	}
}