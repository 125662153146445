[view=history] {

	.page-title {
		& > .count {
			//display: none;
			font-size: 0.875em;
			color: $color-c6;
			font-family: $font-second;
		}
	}

	.message-no-entries {
		display: none;
	}

	.history-list {

		p {
			position: relative;
		}
		a {
			display: inline-block;
		}

		[data-label] {
			position: relative;
			z-index: 100;
			p {
				background-color: $color-c8;
				padding: 0 12px;
				font-size: 13px;
				text-align: center;
				margin: 0 -20px 30px;
			}
		}
	}
}


@include bp-medium {
	[view=history] {
		.history-list {
			
			border-left: 5px solid $color_c6;
			margin-left: 115px;
			padding-left: 75px;

			[data-label] {
				position: relative;

				p {
					display: none;
				}

				&::before {
					content: attr(data-label);
					position: absolute;
					display: block;
					top: 42px;
					right: 100%;
					margin-right: 75px;

					color: $color-c2;
					font-size: 20px;
					font-weight: 700;
					font-family: $font-second;
					line-height: 26px;
					letter-spacing: 1 / 40 * 1em;
				}

				&::after {
					content: '';
					position: absolute;
					display: block;
					width: 15px;
					height: 15px;
					top: 48px;
					left: -65px;
					background-color: $color-c2;
					border-radius: 50%;
				}
			}
		}
	}
}