.paginator-teasers{
	display: none;
}

@include bp-max-medium () {

	[view=topic] {
		position: absolute;
        z-index: 1;
		height: 100%;
		width: 100%;
		background-color: $color-c2;

		#topic_dropdown {
			//display: none;
		}

		#taxonomy_menu {
			position: relative;
			width: 100%;
			padding: 0;
			overflow-x: hidden;
			overflow-y: visible;

            @media (min-width: 768px) {
                padding: $menu-top-height-tablet 0 0;
            }

			& > .panel {
				margin-top: 22px;
				border-bottom: 2px solid $color-c9;
			}

			.menu-block {
				.taxonomy-list {
					position: fixed;
					transform: translate(100%, 0);
					top: -5px;
					left: 0;
					width: 100%;
					min-height: 100%;
					z-index: 50;
					// padding: 0 20px $menu-bottom-height-mobile;

					a {
						font-size: 14px;
						line-height: 28px;

						&::before {
							content: '_';
						}
					}
				}
				

				&.active {
					.taxonomy-list {
						position: relative;
						transform: translate(0%, 0);
						transition: transform 260ms ease;
					}
				}
			}

			.glossary-bar {
				padding: 0;
				border-top: 1px solid $color-c5;
			}
		}

		#topic_teaser_block {
			transition: transform 260ms ease;
			transform: translate(0%, 0);
			position: absolute;
			width: 100%;
			top: 0;
			padding: 0 0 $menu-bottom-height-mobile;
			z-index: 70;
			//&:not([teaser-type=latest]):not([teaser-type=medium]) {
            
            @media (min-width: 768px) {
                padding: $menu-top-height-tablet 0 $menu-bottom-height-mobile;
            }
            
			&[teaser-type=text] {
				// z-index: 70;

				.teaser-block {
					padding-bottom: 30px;
					min-height: 600px;
					min-height: calc( 100vh - 258px );

					.teaser {
						padding-left: 0;
						min-height: 20px;
						margin: 0 20px 8px;

						.head {
							font-family: $font-second;
							line-height: 20px;
							letter-spacing: 0;
						}

						.teaser-body, .teaser-image, .selection, .body {
							display: none;
						}

						&.teaser-medium {
							margin: 0 0 8px;
						}
					}
				}
			}

			& > .panel {
				padding: 72px 0px 0;
				background-color: $color-c4;
				border-bottom: 2px solid $color-c9;
			}

			&[teaser-type=latest] {
				position: relative;
				padding: 0 0 $menu-bottom-height-mobile;

				& > .panel {
					padding-top: 30px;
					background-color: $color-c2;
				}
			}

			.teaser-medium {
				margin-bottom: 18px;
			}

			&.active {
				display: block;
			}

			.teaser-block {
				//background-color: $color-c2;


			}

			.paginator-teasers {
				display: block;
				margin-top: 19px;
				border-top: 1px solid $color-c5;
				padding-top: 10px;
				padding-bottom: 10px;

				a {
					background-color: transparent;
					font-size: 16px;
					font-weight: 700;
					font-family: $font-second;
					letter-spacing: 1/16 * 1em;
					text-decoration: none;
					text-transform: uppercase;
					display: block;
					position: relative;
					white-space: nowrap;
					overflow: hidden;

					height: 25px;
					color: $color-c9;
					line-height: 25px;
					padding: 0 20px;

					&[data-section], &[data-selection] {

						&::before {
							position: absolute;
							@include icon-styles();
							content: "\3c";
							left: 4px;
							top: 0;
							display: block;
							font-size: 17px;
							text-align: center;
							width: auto;
							height: 25px;
							line-height: 25px;

							transform: rotate(0deg);
							transition: transform 230ms ease-in-out;
						}
					}
					&[data-section] {
						color: $color-c6;
					}

					&[data-selection] {
						&::before {
							display: none;
						}
					}
				}
			}
		}

		[view=article] {
			position: absolute;
			width: 100%;
			padding: $menu-top-height-mobile + 55px 0 $menu-bottom-height-mobile;
            
            @media (min-width: 768px) {
                padding: $menu-top-height-tablet + 192px 0 $menu-bottom-height-mobile;
            }
            
			//, & ~ #taxonomy_menu
			& ~ #topic_teaser_block {
				padding-bottom: 0;
				
				> .panel {
					//padding-bottom: 20px;
				}

				.teaser-block {
					display: none;
				}

				.paginator-teasers {
					a {
						&[data-selection] {
							color: $color-c6;

							&::before {
								display: block;
							}
						}
					}
				}
			}

			& ~ #taxonomy_menu {
				> .panel {
					display: none;
				}
			}
		}
	}
}