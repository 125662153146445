$teaser-tiny-width: 96px;
$teaser-tiny-height: 72px;
$teaser-small-width: 152px;
$teaser-small-height: 114px;
$teaser-medium-width: 160px;
$teaser-medium-height: 120px;
$teaser-large-width: 192px;
$teaser-large-height: 144px;
$teaser-text-width: 92px;
$teaser-text-height: 69px;

.teaser {
	position: relative;
	font-family: $font-first;
	font-weight: 400;
	margin-bottom: 30px;

	.teaser-link {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 5;
	}

	.teaser-info {
		overflow-wrap: break-word;
		hyphens: auto;
        word-break: break-word;
	}
	
	.selection {
		font-family: $font-second;
	}

	.teaser-image {
		figcaption {
			display: none;
		}
	}

	.detail-image-wrapper {
		position: relative;
		width: $teaser-tiny-width;
		height: $teaser-tiny-height;
		background-color: $color-c2;
		overflow: hidden;
		
		a {
			display: block;
			line-height: 0;
			font-size: 0;
		}

		img {
			position: absolute;
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.detail-image {

		&.scale-up-x {
			.detail-image-wrapper {
				img {
					max-width: initial;
					max-height: initial;
					width: 100%;
				}
			}
		}
		&.scale-up-y {
			.detail-image-wrapper {
				img {
					max-width: initial;
					max-height: initial;
					height: 100%;
				}
			}
		}
	}

	&.teaser-text {
		.teaser-head {
			.head {
				position: relative;
			}
		}
		.teaser-new-tag {
			position: absolute;
			display: inline-block;
			bottom: 1px;
			color: $color_c2;
			line-height: 1;
			font-size: 11px;
			padding: 1px 5px 3px;
			background-color: $color_c6;
			margin-left: 10px;
			border-radius: 2px;
			//left: 100%;
			
			
			&::before {
				content: '';
				@include triangle(left, $color_c6, 5px);
				position: absolute;
				left: -4px;
				top: 3px;
			}
		}
	}
}

@include bp-max-medium () {
	.teaser:not(.teaser-text) {
		.teaser-image {
			float: left;
			display: inline-block;
			margin-right: 14px;
		}

		.selection {
			font-size: 11px;
			line-height: 1;
			font-weight: 400;
			letter-spacing: 1/22 * 1em;
			margin-bottom: 3px;
		}
		.head {
			font-size: 14px;
			line-height: 18px;
			letter-spacing: 1/28 * 1em;
		}
		.body {
			display: none;
		}

		&::after {
			content: '';
			display: table;
			clear: both;
		}

		.btn-remove {
			font-size: 11px;
			line-height: 25px;
			position: relative;
			top: 8px;
			padding: 0 5px;
			margin-bottom: 4px;
			display: block;
			clear: both;
			background-color: $color-c9;
			z-index: 10;

			&::after {
				float: right;
				line-height: inherit;
				margin-right: 4px;
				vertical-align: middle;
			}
		}
	}

	.teaser-text {
		min-height: 72px;
		padding-left: 106px;
		margin: 0 20px 30px;
		overflow-x: hidden;

		.head {
			font-size: 14px;
			line-height: 18px;
			letter-spacing: 1/28 * 1em;
		}
		.body {
			font-size: 11px;
			line-height: 1;
			font-weight: 400;
			letter-spacing: 1/22 * 1em;
			margin-bottom: 3px;
		}

		.teaser-image {
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

@include bp-medium () {
	.teaser {
		// margin-bottom: 30px;

		&.teaser-tiny {
			
			.teaser-image {
				float: left;
				display: inline-block;
				margin-right: 14px;
			}

			.selection {
				font-size: 11px;
				line-height: 1;
				font-weight: 400;
				letter-spacing: 1/22 * 1em;
				margin-bottom: 3px;
			}
			.head {
				font-size: 14px;
				line-height: 18px;
				letter-spacing: 1/28 * 1em;
			}
			.body {
				display: none;
			}

			&::after {
				content: '';
				display: table;
				clear: both;
			}
		}

		&.teaser-medium {

			margin-bottom: 35px;
			
			.selection {
				display: none;
			}

			.head {
				position: relative;
				z-index: 3;
				font-size: 15px;
				line-height: 20px;
			}

			.body {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: auto;
				max-height: 125px;
				overflow: hidden;

				z-index: 5;
				padding-top: 12px;
				
				font-size: 16px;
				font-weight: 700;
				line-height: 22px;
				letter-spacing: 1/32 * 1em;

				opacity: 0;
			}

			.teaser-image {
				position: relative;
				margin-bottom: 10px;

				z-index: 0;
				opacity: 1;

				.detail-image-wrapper {
					width: $teaser-medium-width;
					height: $teaser-medium-height;
				}
			}

			.body, .teaser-image, &::before {
				transition: opacity 270ms linear;
			}

			&::before {
				content: '';
				position: absolute;
				display: block;
				left: -10px;
				top: 0;
				right: -10px;
				bottom: -10px;
				opacity: 0;
				background: url("/assets/gfx/bg_dots_transparent_2.png") left top repeat $color-c2;
			}

			&:hover, &.active {
				.body {
					opacity: 1
				}

				.teaser-image {
					opacity: 0;
				}

				&::before {
					opacity: 1;
				}
			}
		}

		&.teaser-large {

			.teaser-image {
				float: left;
				display: inline-block;
				margin-right: 20px;

				.detail-image-wrapper {
					width: $teaser-large-width;
					height: $teaser-large-height;
				}
			}

			.teaser-info {
				// hack to avoid text break below the floating image
				display: table;
				
				.head {
					font-size: 18px;
					line-height: 23px;
					font-weight: 400;
					letter-spacing: 1/36 * 1em;
					margin-bottom: 8px;
				}
				.body {
					font-size: 14px;
					line-height: 18px;
					color: $color-c7;
				}
				.selection {
					
					font-size: 12px;
					font-weight: 500;
					line-height: 1;
					letter-spacing: 1/24 * 1em;
					margin-bottom: 6px;
				}
			}

			&::after {
				content: '';
				display: table;
				clear: both;
			}
		}


		&.teaser-text {

			padding: 4px 20px 5px;
			margin-bottom: 0;

			&::before {
				content: '';
				position: absolute;
				display: block;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				opacity: 0;
				background: url("/assets/gfx/bg_dots_transparent_2.png") left top repeat $color-c2;
			}

			&:hover, &.hover {
				&::before {
					opacity: 1;
				}
			}

			.teaser-head {

				line-height: 18px;

				.head {
					position: relative;
					display: inline-block;
					font-family: $font-first;
					font-size: 14px;
					
					&::after {
						content: '';
						display: block;
						width: 0;
						height: 2px;
						position: absolute;
						left: 0;
						bottom: -1px;
						//transition: width 250ms ease-out;
					}
				}
			}

			.teaser-body {
				position: relative;
				max-height: 0;
				padding-top: 0;
				overflow: hidden;

				&::after {
					content: '';
					display: table;
					clear: both;
				}

				.body {
					font-size: 13px;
					line-height: 18px;
				}
			}

			.teaser-image {
				float: left;
				display: inline-block;
				margin-right: 20px;

				.detail-image-wrapper {
					width: $teaser-text-width;
					height: $teaser-text-height;
				}
			}

			&:hover, &.hover {
				padding-bottom: 14px;

				.teaser-body {
					max-height: 94px;
					padding-top: 11px;
				}
			}

			&:hover, &.active, &.hover {
				.teaser-head .head {
					&::after {
						width: 100%;
					}
				}
			}
		}


		&.teaser-small {
			
			margin-bottom: 58px;

			.teaser-info {
				position: relative;
			}

			.teaser-image {
				float: left;
				display: inline-block;
				margin-right: 14px;

				.detail-image-wrapper {
					width: $teaser-small-width;
					height: $teaser-small-height;
				}
			}

			.selection {
				font-size: 12px;
				line-height: 16px;
				font-weight: 500;
				letter-spacing: 1/24 * 1em;
				margin-bottom: 5px;
			}
			.section {
				color: $color-c2;
			}
			.head {
				font-size: 18px;
				line-height: 23px;
				letter-spacing: 1/46 * 1em;
			}
			.body {
				display: none;
			}

			.btn-remove {
				position: absolute;
				right: -10px;
				bottom: -10px;
				color: $color-c2;
				opacity: 0;
				padding: 10px 10px 9px;

				font-family: $font-first;
				font-size: 12px;
				line-height: 15px;

				z-index: 10;
				cursor: pointer;

				transition: opacity 120ms ease, background-color 180ms linear;

				&::after {
					margin-left: 6px;
					font-size: 9px;
				}

				&:hover, &.hover {
					color: $color-c1;
					background-color: $color-c2; 
				}
			}

			&::after {
				content: '';
				display: table;
				clear: both;
			}

			@include bp-medium () {

				&::before {
					content: '';
					display: block;
					position: absolute;
					left: 0px;
					right: 0px;
					top: 0px;
					bottom: 0px;
					z-index: 0;
					opacity: 0;

					transition: opacity 180ms ease-out, top 120ms ease-in-out, right 120ms ease-in-out, bottom 120ms ease-in-out, left 120ms ease-in-out;
				}

				&:hover, &.hover {
					&::before {
						left: -10px;
						right: -10px;
						top: -10px;
						bottom: -10px;
						opacity: 1;
					}
					.selection {
						color: $color-c2;
					}
					.btn-remove {
						opacity: 1;
					}
				}
			}
		}
	}
}


.teaser-event {
	margin-bottom: 17px;
	padding-left: 20px;
	padding-right: 20px;
	position: relative;

	&:last-of-type {
		margin-bottom: 0;
	}

	.head, .teaser-date, .teaser-location {
		font-size: 14px;
		line-height: 18px;
		padding: 2px 0;
		font-family: $font-first;
	}
	.teaser-head {
		
	}

	.head {
		position: relative;
		font-weight: 700;
		display: inline-block;

		&::after {
			content: '';
			display: block;
			width: 0;
			height: 2px;
			position: absolute;
			left: 0;
			bottom: 1px;
			//transition: width 250ms ease-out;
		}
	}

	.teaser-dates {

		.teaser-date {}
	}

	.teaser-link {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 5;
	}

	&:hover, &.active, &.hover {
		.head {
			&::after {
				width: 100%;
			}
		}
	}
}


.partner-teaser {
	display: none;
    position: relative;
	margin: 10px;
	border-width: 2px;
	border-style: solid;
	padding: 12px;

	// clearfix
	overflow: auto;
	zoom: 1;

	transition: opacity 220ms linear;

	.teaser-image {
		text-align: center;
		
		img {
			max-width: 100%;
			height: auto;
		}
	}

	.detail-image-wrapper {
		font-size: 0;
		line-height: 0;
	}

	.teaser-info {

		max-width: 285px;
		.head {
			text-transform: uppercase;
			font-size: 16px;
			line-height: 19px;
			font-weight: 700;
			margin-bottom: 6px;
		}
		.body {
			font-size: 12px;
			line-height: 18px;
		}
	}

	.teaser-link {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 5;

		.partner {
			position: absolute;
			right: 20px;
			bottom: 4px;
			font-size: 12px;
			line-height: 1;
			color: $color-c6;
			display: block;
		}
	}

	.minimized & {
		opacity: 0;
	}
}
@include bp-max-medium () {
	.partner-teaser {
		margin-top: 45px;
		padding-bottom: 20px;
		.teaser-image {
			margin-bottom: 12px;
		}
		.teaser-info {
			margin: 0 auto;
		}
	}
}

@include bp-medium() {
	.partner-teaser {
		position: relative;
		background-color: $color-c2;
		margin: 0 40px 80px;
		overflow: auto;
		zoom: 1;
		border-width: 4px;
		border-style: solid;
		padding: 20px;

		transition: opacity 220ms linear;

		.teaser-image {
			float: right;
			max-width: 142px;

			img {
				max-width: 100%;
				height: auto;
			}
		}

		.detail-image-wrapper {
			font-size: 0;
			line-height: 0;
		}

		.teaser-info {
			max-width: 230px;
			.head {
				text-transform: uppercase;
				font-size: 16px;
				line-height: 19px;
				font-weight: 700;
				margin-bottom: 6px;
			}
			.body {
				font-size: 12px;
				line-height: 18px;
			}
		}

		.teaser-link {
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 5;

			.partner {
				position: absolute;
				right: 20px;
				bottom: 4px;
				font-size: 12px;
				line-height: 1;
				color: $color-c6;
				display: block;
			}
		}

		.minimized & {
			opacity: 0;
		}
	}
}

@include bp-large () {
	.partner-teaser {
		.teaser-image {
			max-width: 300px;
		}

		.teaser-info {
			max-width: 300px;
			.head {
				font-size: 20px;
				line-height: 25px;
				margin-bottom: 8px;
			}
			.body {
				font-size: 16px;
				line-height: 23px;
			}
		}
	}
}