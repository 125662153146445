$taxonomy-menu-line-height: 20px !default;

[view=taxonomy-menu] {

	font-family: $font-first;
	background-color: $color-c4;

	& > .panel {
		position: relative;
		background-color: $color-c4;
	}
	
	.menu-block {
		color: $color_c1;

		& > div {
			margin-bottom: 7px;
		}
	}

	&.has-selection {
		
		.menu-block {
			color: $color_c6;

			&.active {
				color: $color_c1;
			}
		}
	}

	.menu-block .head {

		a {
			text-decoration: none;
			text-transform: uppercase;
			cursor: default;
			line-height: 20px;
			color: $color_c1;
		}
	}

	.menu-block a {
		position: relative;
		display: inline-block;
		font-size: 14px;
		line-height: 18px;
		text-decoration: none;
		vertical-align: top;

		&::after {
			content: '';
			display: block;
			width: 0;
			height: 2px;
			position: absolute;
			left: 0;
			bottom: -2px;
			z-index: 0;
			//transition: width 180ms ease-out;
		}

		.count {
			display: none;
		}

		
	}

	.glossary-bar {
		padding: 6px 20px 5px;
	}

	.glossary-link {
		font-size: 14px;
		line-height: 21px;
		font-family: $font-second;
		letter-spacing: 1 / 32 *1em;
		text-decoration: none;
		// float: right;

		&::before {
			font-size: 20px;
			display: inline-block;
			margin-right: 8px;
			vertical-align: text-bottom;
		}
	}

	.head {
		font-weight: 700;
		font-size: 14px;
		line-height: $taxonomy-menu-line-height;
	}
}

@include bp-max-medium () {
	[view=taxonomy-menu] {

		.menu-block {
			border-top: 1px solid $color-c5;
	
			.head {
				position: relative;
				margin: 0 -20px;

				a {
					position: relative;
					display: block;

					padding: 0 20px;
					font-family: $font-second;
					line-height: 45px;
					font-size: 16px;
					color: $color_c9;
					letter-spacing: 1/32 * 1em;

					&::after {
						@include icon-styles();
						content: "\76";
						right: 15px;
						top: 0;
						display: block;
						font-size: 20px;
						position: absolute;
						z-index: 12;
						padding: 0 13px;
						width: 45px;
						height: 45px;
						line-height: 45px;
						left: auto;

						transform: rotate(0deg);
						transition: transform 230ms ease-in-out;
					}
				}
			}

			&.active {
				.head a {
					&::after {
						transform: rotate(180deg);
					}
				}
			}
		}

		.glossary-link {
			display: block;
			line-height: 29px;
			padding-left: 20px;
			padding-right: 20px;
			color: $color_c1;
			background-color: $color-c4;
			float: none;

			&::before {
				font-size: 18px;
			}
		}

		.head {
			line-height: 45px;
		}
	}
}

@include bp-medium () {

	[view=taxonomy-menu] {

		& > .panel {
			width: 700px;
			left: 60px;
			top: $menu-top-height-desktop + 53px;
			padding: 40px 40px 40px 0;
			margin-bottom: 60px + $menu-bottom-height-desktop;
			background-color: $color_c3;
			box-shadow: 0 0 6px -3px #000;
		}
		
		.menu-block {
			transition: color 320ms linear;

			a {
				margin-bottom: 6px;

				.count {
					position: absolute;
					display: inline-block;
					bottom: 0px;
					color: $color_c2;
					line-height: 1;
					font-size: 11px;
					padding: 2px 6px 3px;
					background-color: $color_c6;
					margin-left: 40px;
					border-radius: 2px;
					left: 100%;
					opacity: 0;

					transition: margin-left 220ms ease 160ms, opacity 220ms linear 160ms;

					&::before {
						content: '';
						@include triangle(left, $color_c6, 5px);
						position: absolute;
						left: -4px;
						top: 3px;
					}
				}

				&:hover {
					color: $color_c1;

					.count {
						margin-left: 10px;
						opacity: 1;
					}
				}

				&.active, &:hover, &:focus {
					&::after {
						width: 100%;
					}
				}
			}
		}

		.glossary-bar {
			position: relative;
			top: 12px;
			// bottom: 2px;
			width: 100%;
			padding: 0px 25px 0px;
			text-align: right;
			// border-top: 1px solid $color_c4;
		}
	}
}