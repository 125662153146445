#eye_catcher {
	display: none;
}

@include bp-medium () {
	
	#eye_catcher {
		display: block;
	}

	.eye-catcher {
		position: fixed;
		left: -3px;
		bottom: 100px;
		z-index: 5;

        &.hidden-when-closed {
            display: none;
        }

		div {
			box-shadow: 0 0 6px -2px #000;
		}

		.text-bubble {
			position: absolute;
			left: 15px;
			bottom: 20px;
			width: 0;
			height: 0;
			border-radius: 50px;
			// display: flex;
			// align-items: center;
			display: block;
			z-index: 4;
			overflow: hidden;

			.bubble-content {
				position: absolute;
				display: block;
				width: 200px;
				top: 50%;
				transform: translate(0, -50%);
				color: white;
				font-size: 14px;
				line-height: 1.4;
				font-family: $font-second;
				text-align: center;
				padding: 20px;
				opacity: 0;
				text-decoration: none;

				.title {
					
				}
			}
		}

		.large-bubble {
			position: absolute;
			left: 15px;
			bottom: 15px;
			width: 30px;
			height: 30px;
			border-radius: 15px;
		}

		.small-bubble {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 15px;
			height: 15px;
			border-radius: 8px;
		}

		.close-bubble {
			position: absolute;
			left: 205px;
			bottom: 100px;
			width: 0;
			height: 0;
			border-radius: 12px;
			cursor: pointer;
			display: none;
			z-index: 5;
		}

		.icon-close:before {
			font-size: 11px;
			font-weight: 700;
			margin-left: 6px;
			vertical-align: text-bottom;
			color: white;
		}
	}

	.eye-catcher-placeholder {
		position: fixed;
		bottom: 100px;
		left: -3px;
		z-index: 11;
		width: 15px;
		height: 15px;
		border-radius: 8px;
		display: none;
	}
}