/*
Berufsgruppenumfrage für Rathscheck Thema Schiefer
*/

$schiefer-color: #245464;

.profession-poll {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($schiefer-color, 0.6);

    &.active {
        display: block;
        z-index: 1060;
    }

    .profession-poll-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 90%;
        min-height: 300px;
        max-width: 720px;
        min-width: 320px;
        background-color: #fff;
        padding: 30px;
        border: 1px solid $schiefer-color;

        line-height: 1.4;

        .btn-close-panel {
            position: absolute;
            z-index: 2;
            right: 10px;
            top: 10px;
            cursor: pointer;
            text-decoration: none;

            &::before {
                position: relative;
                @include icon-styles();
                content: "\58";

                display: block;
                font-size: 26px;
                text-align: center;
                height: 26px;
                width: 26px;
                line-height: 26px;

                transform: rotate(0deg);
                transition: transform 230ms ease-in-out;
            }
        }

        strong {
            font-weight: bold;
            font-size: 18px;
            display: inline-block;
            margin: 0 0 1em;
        }
        
        form {
            width: 100%;
            
            .flex-row {
                display: flex;
                flex-flow: column;
            }
            
            label {
                font-size: 16px;
                display: block;
                margin: 0 0 12px;
                cursor: pointer;
                width: 100%;
            }

            input[type=text] {
                font-family: $font-first;
                font-size: 16px;
                color: #000;
                border: 1px solid #000;
                padding: 3px;
            }

            button {
                margin-top: 12px;
            }
        }

        .poll-page-1, .poll-page-2 {
            position: relative;
            display: none;

            &.active {
                display: block;
            }
        }
    }
}

@include bp-small () {
    .profession-poll {
        .profession-poll-content {
            form {
                .flex-row {
                    flex-flow: row wrap;
                    justify-content: space-between;
                }
                
                .flex-col-2 {
                    width: 50%;
                }
            }
        }
    }
}
