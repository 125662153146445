
[view=topic-teaser] {

    & > .panel {
        background-color: $color-c2;
    }

    .topic-teaser-head {
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        font-size: 24px;
        letter-spacing: 2px;
        margin-bottom: 26px;
    }

    .topic-teaser-info {
        display: none;
    }

    .event-teaser-head {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;

        padding-bottom: 9px;
        margin: 0 20px 20px;

        background: center bottom repeat no-repeat transparent;
        background-image: $gfx-text-divider;
    }

    .teaser-event + .event-teaser-head {
        margin-top: 24px;
    }
}

@include bp-medium () {

    [view=topic-teaser] {
        & > .panel {
            position: absolute;
            top: $menu-top-height-desktop + 112px;
            left: 730px;
            border-width: 1px;
            border-style: solid;

            background-color: $color-c3;
            box-shadow: 0 0 6px -3px #000;
            margin-bottom: 240px;

            & > .wrapper {
                width: 480px;
                padding: 40px 0;
            }

            .btn-close-panel {
                position: absolute;
                z-index: 2;
                right: 10px;
                top: 10px;
                cursor: pointer;
                text-decoration: none;

                &::before {
                    position: relative;
                    @include icon-styles();
                    content: "\58";

                    display: block;
                    font-size: 26px;
                    text-align: center;
                    height: 26px;
                    width: 26px;
                    line-height: 26px;

                    transform: rotate(0deg);
                    transition: transform 230ms ease-in-out;
                }

            }

        }

        &[teaser-type=latest] {
            & > .panel {

                & > .wrapper {

                    padding-top: 34px;
                }
            }
        }

        &[teaser-type=objekte] {
            & > .panel {
                & > .wrapper {
                    width: 444px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }

        .paginator-teasers {
            display: block;
            padding: 0 0 0 20px;
            position: relative;
            top: -1em;
            z-index: 1;

            a {
                line-height: 1;
                text-transform: uppercase;
                text-decoration: none;
                font-family: $font-second;
                font-weight: 700;
                font-size: 14px;
                padding: 0;
                display: inline;
                background-color: transparent;
                cursor: default;
                pointer-events: none;

                &:first-child:after {
                    content: " » ";
                    font-size: 18px;
                }
            }
        }
    }
}

@include bp-large () {
    [view=topic-teaser] {
        &[teaser-type=latest] {
            & > .panel {

                & > .wrapper {
                    width: 690px;
                    padding-top: 34px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

        }

        &[teaser-type=objekte] {
            & > .panel {
                & > .wrapper {
                    width: 646px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
    }
}

@include bp-xlarge() {

    [view=topic-teaser] {

        &[teaser-type=objekte] {
            & > .panel {
                & > .wrapper {
                    width: 848px;
                    padding-left: 20px;
                    padding-right: 20px;

                    .teaser-block {
                        .xlarge-4 {
                            max-width: 202px;
                        }
                    }
                }
            }
        }

    }
}