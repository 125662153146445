// test objects
.overscroll {
	position: relative;
	overflow: hidden;

	.overscroll-indicator {
		position: relative;
		//overflow: hidden;
		//height: 50vh;
		//min-height: 320px;
		width: 100%;
		//background-color: $color-c2;
		padding-bottom: $menu-bottom-height-desktop;

		// transition: height 600ms ease-in-out;

		.overscroll-label {
			//position: relative;
			text-align: center;
			font-size: 21px;
			line-height: 30px;
			padding: 11px 20px 9px;
			background-color: $color-c3;
			font-family: $font-first;
			font-weight: 400;
			//z-index: 1;
		}

		.overscroll-link {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			span {
				@include visuallyhidden();
			}
		}

		.overscroll-headline {
			//position: relative;
			font-size: 24px;
			font-weight: 300;
			line-height: 32px;
			letter-spacing: 1/68 * 1em;
			
			a {
				display: block;
				padding: 15px;
				text-decoration: none;
			}
		}
	}

	/* overscroll nicht anzeigen auf Wunsch */
    .overscroll-progress {
		display: none;
		position: absolute;
		height: 5px;
		width: 0%;
		left: 0;
		top: 0;
		z-index: 3;
	}
}

.article-next {
    width: 100%;
    text-align: center;
    padding: 0 0 42px;

    a {
        display: block;
        width: 100%;
        font-size: 21px;
        line-height: 30px;
        padding: 11px 20px 9px;
        font-family: $font-first;
        font-weight: 400;
        text-decoration: none;
    }
}

@include bp-max-medium () {
	.overscroll .overscroll-indicator { display: none; }
}

@include bp-medium () {
    .article-next {
        display: none;
    }
    
	.overscroll {
		.overscroll-indicator {
			box-shadow: 0 0 5px -3px #000;

			/*margin-top: -120px;
			.overscroll-headline {
				font-size: 32px;
		    	line-height: 40px;
				letter-spacing: 1/32 * 1em;
				
				&:not(.small) {
					font-size: 38px;
					line-height: 50px;
					letter-spacing: 1/38 * 1em;
				}

				a {
					padding: 30px;
				}
			}
			*/
		}
	}
}

@include bp-large () {
	.overscroll {
		.overscroll-indicator {
			.overscroll-headline {
				
				&:not(.small) {
					font-size: 48px;
					line-height: 58px;
				}
			}
		}
	}
}