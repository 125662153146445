.hidden-dialog {
	display: none;
}

.dialog {
	position: fixed;
	background-color: $color-c6;
	color: $color-c2;
	padding: 16px;

	.dialog-head {
		font-size: 16px;
		font-weight: 700;
		line-height: 19px;
		letter-spacing: 1 / 32 * 1em;
		margin-bottom: 6px;
	}
	.dialog-body {
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 1 / 26 * 1em;
		margin-bottom: 8px;
	}

	.dialog-head, .dialog-body {
		@include text-sharpen();
	}

	.dialog-confirm {
		display: inline-block;
		padding: 4px 16px 5px;
		background-color: $color-c2;
		color: $color-c6;
		font-family: $font-second;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 1 / 24 * 1em;
		text-transform: uppercase;
		text-decoration: none;
		cursor: pointer;
	}
}


@include bp-medium () {
	.dialog {
		.dialog-confirm {
			transition: background-color 180ms linear;
			&:hover {
				background-color: $color-c4;
			}
		}	
	}
}



#cookie_confirmation, #cookie_enable {
	right: 0;
	bottom: $menu-bottom-height-mobile;
	width: 100%;
	max-width: 320px;
	padding-right: 32px;

	&::after {
		@include icon-styles();
		content: "\e909";
		display: block;
		position: absolute;
		bottom: 4px;
		right: 4px;
		font-size: 24px;
	}
}

@include bp-medium () {
	#cookie_confirmation, #cookie_enable {
		right: 0;
		top: $menu-top-height-desktop;
		bottom: auto;
		z-index: 6000;
		max-width: 380px;

		&::after {
			content: "\e909";
			//content: "\e90a";
			bottom: auto;
			top: 7px;
			right: 43px;
			transform: rotate(180deg);
		}
	}
}
