$column-count: 12 !default;

$row-selector: '.row' !default;
$column-selector: '.columns' !default;

$gap-size: 40px !default;
$gap-size-inner: 12px !default;

@function grid-calc ( $colNumber, $totalColumns ) {
	@return percentage(( $colNumber / $totalColumns ));
}

@mixin grid-base-styles {
	#{$row-selector} {
		line-height: 0;
		font-size: 0;
		
		&:after {
			content: " ";
			display: table;
			clear: both; 
		}

		/*
		& + #{$row-selector} {
			margin-top: $gap-size;
		}
		*/

		#{$row-selector} {
			margin-left: -0.5 * $gap-size;
			margin-right: -0.5 * $gap-size;
		}

		#{$column-selector} {
			width: 100%;
			display: inline-block;
			vertical-align: top;
			line-height: 1rem;
			font-size: 1rem;

			padding-left: 0.5 * $gap-size;
			padding-right: 0.5 * $gap-size;

			img {
				max-width: 100%;
				height: auto;
			}

			&.align-bottom {
				vertical-align: bottom;
			}
		}

		&.collapse {

			#{$column-selector} {
				padding-left: 0;
				padding-right: 0;
			}

			#{$row-selector} {
				margin-left: 0;
				margin-right: 0;
			}
		}

		&.expand {
			margin-left: -0.5 * $gap-size;
			margin-right: -0.5 * $gap-size;
		}

		&.small-gaps {
			margin-left: -0.5 * $gap-size-inner;
			margin-right: -0.5 * $gap-size-inner;

			#{$column-selector} {
				padding-left: 0.5 * $gap-size-inner;
				padding-right: 0.5 * $gap-size-inner;
			}
		}
	}
}



@mixin column-styles-live($size) {

	@for $i from 1 through $column-count {

		#{$column-selector}.#{$size}-#{$i} {
		
			width: grid-calc($i, $column-count);
			width: calc(100% / #{ $column-count / $i } );
			
		}


		// Push 
		#{$column-selector}.#{$size}-push-#{$i} {
			left: grid-calc($i, $column-count);
			position: relative;
		}

		// Pull
		#{$column-selector}.#{$size}-pull-#{$i} {
			left: -1 * grid-calc($i, $column-count);
			position: relative;
		}
		
	}

	#{$row-selector}.equalize-#{$size} {
		display: table;
		width: 100%;
		table-layout: fixed;

		& > #{$column-selector} {
			display: table-cell;
			vertical-align: middle;
			height: 100%;

			&.equalize-top {
				vertical-align: top;
			}
		}
	}
}

