/* https://google-webfonts-helper.herokuapp.com */

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
       url('/assets/fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
    font-display: swap;
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('/assets/fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
    font-display: swap;
}
/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/roboto-v20-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Italic'), local('Roboto-Italic'),
       url('/assets/fonts/roboto-v20-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/roboto-v20-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/roboto-v20-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
    font-display: swap;
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('/assets/fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
    font-display: swap;
}

/* roboto-mono-300 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/roboto-mono-v7-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Mono Light'), local('RobotoMono-Light'),
       url('/assets/fonts/roboto-mono-v7-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/roboto-mono-v7-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/roboto-mono-v7-latin-300.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/roboto-mono-v7-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/roboto-mono-v7-latin-300.svg#RobotoMono') format('svg'); /* Legacy iOS */
    font-display: swap;
}
/* roboto-mono-regular - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/roboto-mono-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Mono'), local('RobotoMono-Regular'),
       url('/assets/fonts/roboto-mono-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/roboto-mono-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/roboto-mono-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/roboto-mono-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/roboto-mono-v7-latin-regular.svg#RobotoMono') format('svg'); /* Legacy iOS */
    font-display: swap;
}
/* roboto-mono-500 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/roboto-mono-v7-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Mono Medium'), local('RobotoMono-Medium'),
       url('/assets/fonts/roboto-mono-v7-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/roboto-mono-v7-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/roboto-mono-v7-latin-500.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/roboto-mono-v7-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/roboto-mono-v7-latin-500.svg#RobotoMono') format('svg'); /* Legacy iOS */
    font-display: swap;
}
/* roboto-mono-700 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/roboto-mono-v7-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Mono Bold'), local('RobotoMono-Bold'),
       url('/assets/fonts/roboto-mono-v7-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/roboto-mono-v7-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/roboto-mono-v7-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/roboto-mono-v7-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/roboto-mono-v7-latin-700.svg#RobotoMono') format('svg'); /* Legacy iOS */
    font-display: swap;
}

$font-first: 'Roboto', sans-serif !default;
$font-second: 'Roboto Mono', monospace !default;

body {
	font-family: $font-first;
}

a {
	color: inherit;
}

dl {
	dt {
		font-weight: 700;
	}
}

strong, b {
	font-weight: 700;
}

sub, sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}