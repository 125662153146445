@include grid-base-styles();	

/* use media queries for fluid layout sizing */

@include column-styles-live('small');

@media (min-width: $bp-small) {
	@include column-styles-live('medium');
} 
@media (min-width: $bp-medium) {
	@include column-styles-live('large');
} 
@media (min-width: $bp-large) {
	@include column-styles-live('xlarge');
} 