#baunetz-superheader {
    .tt-hint {
        color: $color_c6;
        height: 100%;
    }

    .twitter-typeahead {
        vertical-align: top;
        display: table-cell !important;
        width: 100%;
    }

    .tt-menu {
        color: $color_c2;
        background-color: $color-c1;
        margin-top: 20px;
        padding: 15px 0 10px 0;
        white-space: nowrap;
        cursor: default;
        transition: transform .22s ease;

        &::before {
            content: "";
            @include triangle(top, $color-c1, 10px);
            position: absolute;
            top: -20px;
            left: 10px;
            height: 20px;
        }
    }

    .tt-selectable {
        padding: 3px 20px 1px 10px;

        &:hover, &.tt-cursor {
            background-color: $color_c5;
        }
    }
    /*
    .baunetz-superheader {
        min-height: $menu-top-height-mobile;
    
        @media (min-width: 768px) {
            min-height: $menu-top-height-tablet;
        }
    }
    */
}
