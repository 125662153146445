#debug_toggle {
	position: fixed;
	right: 0;
	bottom: $menu-bottom-height-desktop;
	background-color: white;
	padding: 8px 15px;
	font-weight: 700;
	z-index: 10001;
}

#debug {
	position: fixed;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	left: 0;
	top: 0;
	z-index: 10000;
	display: none;
	background-color: white;
	padding: 40px;

	&.active {
		display: block;
	}
}