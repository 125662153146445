.symbol {
    position: relative;
}

.symbol-arrow-up {
    width: $menu-bottom-height-mobile;
    height: $menu-bottom-height-mobile;

    &::before, &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: $color-c1;
        height: 4px;
        width: 16px;
    }

    &::before {
        transform-origin: 2px 2px;
        transform: translate(-2px, -6px) rotate(45deg);
    }

    &::after {
        transform-origin: 14px 2px;
        transform: translate(-14px, -6px) rotate(-45deg);
    }
}
