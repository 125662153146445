@mixin bp ($breakpoint) {
	@media screen and (min-width: $breakpoint) {
		@content;
	}
}

@mixin bp-small() {
	@include bp($bp-small) {
		@content;	
	}
}

@mixin bp-max-small() {
	@media screen and (max-width: -1 + $bp-small) {
		@content;
	}
}

@mixin bp-max-medium() {
	@media screen and (max-width: -1 + $bp-medium) {
		@content;
	}
}

@mixin bp-medium() {
	@include bp($bp-medium) {
		@content;	
	}
}

@mixin bp-large() {
	@include bp($bp-large) {
		@content;	
	}
}

@mixin bp-xlarge() {
	@include bp($bp-xlarge) {
		@content;	
	}
}


@mixin text-sharpen () {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


@mixin clearfix() {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}


// http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/
@mixin visuallyhidden() {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}



/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color 
/// @param {Length} $size [1em] - Triangle size
@mixin triangle($direction, $color: currentcolor, $size: 1em) {
	@if not index(top right bottom left, $direction) {
		@error "Direction must be either `top`, `right`, `bottom` or `left`.";
	}

	content: '';
	z-index: 2;

	height: 0;
	width: 0;

	@if $direction == top {
		border-bottom: $size solid $color;
		border-left: $size solid transparent;
		border-right: $size solid transparent;
	} @else if $direction == right {
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
		border-top: $size solid transparent;
	} @else if $direction == down {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
	} @else if $direction == left {
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
		border-top: $size solid transparent;
	}
}
