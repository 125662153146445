[view=watchlist], [view=watchlist_shared] {

	.page-title {
		& > .count {
			//display: none;
			font-size: 0.875em;
			color: $color-c6;
			font-family: $font-second;
		}
	}

	.list-button {

		display: inline-block;
		background-color: $color-c9;
		color: $color-c4;
		padding: 7px 12px;
		font-size: 14px;
		letter-spacing: 1/28 * 1em;
		line-height: 18px;
		cursor: pointer;
	}

	.button-set {
		position: relative;
		.label {
			display: block;
			font-size: 11px;
			line-height: 16px;
			position: absolute;
			top: -16px;

		}
		.list-button {
			
		}
	}

	.list-controls {
		font-size: 0;
		margin: 35px 0 33px;
		
		@include clearfix();
		
		> p {
			display: block;
			margin-right: 20px;
			font-size: 0;
			line-height: 0;
			vertical-align: bottom;
			margin-bottom: 28px;
		}

		.button-set {
			position: relative;



			.label {
				position: absolute;
				top: -15px;
				left: 1px;
			}

			input {
				display: inline-block;
				background-color: $color-c9;
				color: $color-c4;
				padding: 7px 12px;
				font-size: 14px;
				width: 130px;
				letter-spacing: 1/28 * 1em;
				line-height: 18px;
				cursor: pointer;

				border: 0 none transparent;
				vertical-align: top;

				box-shadow: 0 0 0 0 transparent;

				&:focus {
					box-shadow: 0 0 0 0 transparent;
				}
			}
		}
		.share-block {
			display: inline-block;
			vertical-align: bottom;
			margin-bottom: 28px;
			margin-right: 20px;
		}
	}

	#list_id_display {
		min-width: 90px;
		font-size: 16px;
		text-decoration: none;
		text-align: center;
		font-family: $font-second;
	}
}

[view=watchlist] {
	.page-head {
		hr {
			margin-top: 30px;
		}
	}

	.watchlist-teaser {
		p {
			position: relative;
		}
	}

	.watchlist-share {
		// margin-top: 20px;

		.button-set {
			.label {
				
			}
			.list-button {
				text-transform: uppercase;
				font-size: 18px;
				padding: 12px;
				font-family: $font-second;
			}
		}
	}

	
}

@include bp-max-medium () {
	[view=watchlist] {
		.button-set {
			margin: 20px 0 25px;
		}

		.list-controls {
			> *:last-child {
				margin-bottom: 0;
			}
		}
	}
}


@include bp-medium () {
	[view=watchlist], [view=watchlist_shared] {
		.list-controls {
			
			> p, > .share-block {
				display: inline-block;
				margin-bottom: 0;

				&.right {
					float: right;
				}
			}
		}
	}
	[view=watchlist] {

		.watchlist-share {
			// margin-top: 40px;
			
			.button-set {
				.label {}
				.btn-generate {
					&:hover {
						background-color: $color-c8;
					}
				}
			}
		}

		.share-block {
			&.disabled {
				&::before {
					position: absolute;
					content: "\2192";
					left: -72px;
					font-size: 48px;
					display: block;
					bottom: 17px;
					color: $color-c2;
				}
			}
		}
	}
}