$article-width-medium: 522px !default;
$article-width-large: 740px !default;

[view=article] {
    font-family: $font-first;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    position: relative;
    z-index: 10;
    color: $color-c1;

    & > .panel {
        & > .wrapper {
            background-color: $color-c2;
        }
    }

    & ::selection {
        color: white;
        background-color: black;
    }

    .quellen {
        margin-top: 1em;
        font-size: 12px;
    }

    .menu_print {
        margin: 50px 0 0 0;
        padding-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            display: inline-block;

            a {
                font-size: 30px;
                font-family: $font-second;
                font-weight: 500;
                text-decoration: none;
            }
        }

        .partner_slider_print {
            display: inline-block;
            min-height: 87px;

            .partner-slide {

                img {
                    max-width: 240px;
                    max-height: 87px;
                    width: auto;
                    height: auto;
                }

            }
        }
    }

}

[article-module] {
    padding: 0 15px;
    margin-bottom: 20px;

    h3 {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 1/24 * 1em;
        line-height: 1;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    b, strong {
        font-weight: 700;
    }

    i {
        font-style: italic;
    }
}

[article-module=headline] {
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: 1/68 * 1em;
    margin-bottom: 18px;
}

[article-module=subheadline] {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 1/36 * 1em;
    margin-bottom: 22px;
    text-transform: uppercase;
}

[article-module=textblock] {
    h3 {
        font-size: 16px;
        line-height: 1.6;
        letter-spacing: initial;
        font-weight: 700;
        text-transform: none;
        margin-bottom: 0;
    }

    ul {
        padding-left: 20px;
        margin-bottom: 26px;
        margin-top: 14px;

        li {
            &::before {
                content: '-';
                margin-left: -18px;
                width: 14px;
                display: inline-block;
                position: absolute;
            }
        }
    }
    //> p {
    //	margin-bottom: 26px;
    //}

    p:empty {
        display: none;
    }

    p + p {
        margin-top: 26px;
    }

    p + h3 {
        margin-top: 26px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .glossarlink {
        text-decoration: none;
        background: url(data:image/gif;base64,R0lGODlhAQAFAIAAAM3NzQAAACH5BAAAAAAALAAAAAABAAUAAAIChF0AOw==) repeat-x transparent bottom left;
        
        &:hover {
            background: #cdcdcd;
            //opacity: 0.8;
        }
    }
}

[article-module=gallery], [article-module=related_article], [article-module=related_objects], [article-module=related_tips], [article-module=video_embed], [article-module=webtips], [article-module=object_information], [article-module=object_map], [article-module=architect_profile], [article-module=downloads], [article-module=event], [article-module=book] {
    padding-top: 20px;
    padding-bottom: 40px;
    background-color: $color-c3;
}

[article-module=gallery], [article-module=video_embed] {
    figcaption {
        position: relative;
        padding: 20px 0 0;
    }
    .caption {
        font-size: 14px;
        line-height: 20px;
    }
    .copyright {
        font-size: 12px;
        line-height: 20px;
    }
}

[article-module=gallery] {
    .caption {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

[article-module=gallery] {

    .index {
        font-family: $font-second;
        font-size: 18px;
        font-weight: 300;
        line-height: 1;
        float: right;
    }

    .detail-image-wrapper {
        position: relative;
        height: 240px;
        // margin: 0 40px;
        text-align: center;

        img {
            position: relative;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            top: 50%;
            transform: translate(0, -50%);
        }

        & > .pswp-link {
            display: block;
            position: relative;
            height: 100%;
        }

    }

    .gallery-slide {
        position: relative;
    }

    .gallery-link {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-decoration: none;
        z-index: 2;
        cursor: zoom-in;

        &:focus {
            outline: none;
        }
    }
}

[article-module=video_embed] {
    .video-wrapper {
        position: relative;
        max-width: 100%;
        padding-top: 56.25%;

        iframe, .video-link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .video-link {
            display: block;
            overflow: hidden;
            text-decoration: none;

            &::after {
                @include icon-styles();
                content: "\70";
                color: white;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 64px;
            }

            img {
                position: relative;
                top: 50%;
                width: 100%;
                height: auto;
                transform: translate(0, -50%);
            }
        }
    }
}

[article-module=book] {

    display: flex;
    flex-flow: row wrap;

    .book-cover {
        margin-right: 40px;
    }

    .book-details {
        flex-basis: 55%;
    }

    .book-author {
        font-weight: 700;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 6px;
    }

    .book-info, .book-price, .book-isbn {
        font-size: 14px;
        line-height: 22px;
    }

    .book-isbn {
        font-size: 14px;
    }
}

[article-module=related_article], [article-module=related_objects], [article-module=related_tips] {

    .teaser-block {
        margin-left: -20px;
        margin-right: -20px;
    }
}

[article-module=object_map] {
    .map {
        display: inline-block;
        position: relative;
        min-height: 112px;
        //margin-top: 44px;
        border: 1px solid $color-c5;

        a {
            display: block;
            cursor: pointer;
            position: relative;

            /*
			&::after {
				@include icon-styles();
				content: "\e90b";
				display: block;
				position: absolute;
				font-size: 42px;
				line-height: 1;
				height: 42px;
				left: 50%;
				bottom: 50%;
				margin-left: -21px;
			}
            */
        }

        img {
            height: auto;
            display: block;
        }
    }
}

[article-module=object_information] {
    .infos {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        h3 {
            font-size: 14px;
            line-height: 22px;
            position: relative;
        }

        .iconafter-architect_profile {
            font-size: 24px;
            position: absolute;
            top: -3px;
            margin-left: 6px;
        }
    }

    [article-module=object_map] {
        padding: 0;
        margin: 20px 12px 0 0;
    }

    [article-module=architect_profile] {
        padding: 0;
        margin: 20px 0 0 0;
    }
}

[article-module=share] {

    .share-row {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .social-links {
        min-width: 180px;
        flex: 2;
        align-self: flex-end;
        position: relative;
    }

    .share-artikel-actions {
        flex: 1;
        align-self: flex-end;
    }

    p {
        font-size: 14px;
        line-height: 32px;
    }

    .share-link {
        position: relative;
        display: block;
        line-height: 32px;
        text-decoration: none;
        vertical-align: middle;

        &::before {
            font-size: 28px;
            color: $color_c9;
            display: inline-block;
            position: relative;
            width: 37px;
            vertical-align: middle;
        }

        &.icon-social_linkedin:before {
            font-size: 22px;
        }

        span {
            font-size: 14px;
        }

    }
    
    .newsletter-share-link {
        img {
            display: inline-block;
            vertical-align: middle;
            padding: 0 6px;
        }
    }

    .share-artikel-actions {
        a {
            width: 72px;
            display: inline-block;
            text-decoration: none;
            cursor: pointer;

            &:not(:hover) {
                color: $color_c9;
            }

            span {
                display: block;
                margin-bottom: 6px;

                font-family: $font-second;
                font-weight: 500;
                font-size: 11px;
                line-height: 13px;
                letter-spacing: 1 / 26 * 1em;
                text-align: center;
            }

            &::after {
                display: block;
                text-align: center;
                font-size: 30px;
                line-height: 30px;
            }

            &.print-link {
                &::after {
                    font-size: 27px;
                }
            }

            & + a {
                margin-left: 6px;
            }

            &.watchlist-trigger {
                &.checked, &.checked:hover {
                    color: $color_c5;
                }
            }
            
            &.newsletter-link {
                .newsletter-icon {
                    margin: 12px auto 0 auto;
                    display: block;
                }
            }
        }
    }
}

[article-module=event] {
    display: flex;
    flex-flow: row wrap;
    
    @include bp-medium() {
        flex-flow: row nowrap;
    }
    
    .event-cover {
        margin-right: 40px;
        margin-bottom: 15px;
        font-size: 0;
        line-height: 0;
        
        .detail-image {
            width: 220px;
        }
        
        img {
            max-width: 100%;
            width: auto;
            max-height: 165px;
            height: auto;
        }
        
        .copyright {
            margin-top: 6px;
            font-size: 11px;
            line-height: initial;
        }
    }

    dl {
        margin-top: -3px;
        flex-basis: 55%;
    }
}

[article-module=event], [article-module=book] {
    .detail-image a {
        cursor: default;
    }
}

//unsichtbar - nur druckbar
[article-module=partner-contact] {
    display: none;
}

[article-module=architect_profile] {
    h3 {
        margin-bottom: 3px;
    }

    .external-link-list li {
        padding-bottom: 0;
    }
}

@include bp-small () {
    [article-module=headline] {
        font-size: 32px;
        line-height: 41px;
    }

    [article-module=object_information] {
        [article-module=architect_profile] {
            flex-basis: 45%;
        }
    }

    [article-module=event] {
        .event-cover {
            margin-bottom: 0;
        }
    }
}

@include bp-max-medium () {
    [view=article] {
        overflow-x: hidden;
        background-color: $color-c2;

        & > .panel {
            max-width: $article-width-large;
            margin-left: auto;
            margin-right: auto;
        }

        .article-back-to-top {
            padding: 0;
            width: 100%;

            a {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-end;
                width: 100%;
                line-height: 31px;
                text-decoration: none;
            }
        }
        
        .article-back-to-top:last-child {
            padding: 0 0 42px;
        }
    }

    [view=article] [article-module=share] {

        .head {
            display: block;
            margin-bottom: 8px;
        }

        .share-link {
            background-color: $color-c4;
            padding: 2px;

            &:not(:last-of-type) {
                margin-bottom: 20px;
            }

            &::before {
                text-align: center;
                margin-right: 8px;
            }
        }

        .share-artikel-actions {
            a {
                display: block;
                background-color: $color-c4;
                margin-left: auto;
                padding: 4px;

                &:not(:last-of-type) {
                    margin-top: 8px;
                }
                & + a {
                    margin-left: auto;
                }
            }
        }
    }
}

@include bp-medium () {

    [view=article] {
        & > .panel {
            position: absolute;
            width: $article-width-medium;

            & > .wrapper {
                padding: 23px 0 15px;
                box-shadow: 0 0 6px -3px #000;
                margin-bottom: 80px;
            }
        }

        .article-back-to-top {
            display: none;
        }
    }

    [view=topic] [view=article] {

        > .panel {

            min-height: 100%;
            top: $menu-top-height-desktop + 29px;
            right: 30px;
            z-index: 10;

            transition: background-color 240ms linear;

            &.minimized {
                overflow-x: hidden;
                background-color: #FFF;
            }
        }

        .placeholder {
            position: fixed;
            width: 740px;
            max-width: 78vh;
            height: 150px;
            line-height: 150px;
            transform-origin: 0 50%;
            left: 100%;
            top: 50%;
            transform: rotate(-90deg) translate(-50%, -50%);

            h2 {
                display: inline-block;
                font-size: 38px;
                line-height: 50px;
                margin: 0;
                vertical-align: middle;
            }
        }
    }

    [view=glossary] [view=article] {

        position: relative;
        float: right;
        top: 0;
        width: $article-width-medium;

        &.animate-out {
            position: absolute;
            right: 0;
        }

        & > .panel {
            position: relative;

            right: 0;
            top: 0;

            & > .wrapper {
                margin-bottom: 75px;
            }
        }
    }

    [article-module] {
        padding: 0 30px;
        margin-bottom: 20px;

        h3 {
            font-size: 22px;
            letter-spacing: 2/22 * 1em;
        }
    }

    [article-module=textblock] {
        h3 {
            font-size: 16px;
            line-height: 1.6;
            letter-spacing: initial;
            font-weight: 700;
            text-transform: none;
            margin-bottom: 0;
        }
    }

    [article-module=share] {

        .social-links {
            position: relative;
            top: -5px;
        }

        .share-link {

            display: inline-block;
            width: 32px;
            text-align: center;

            &::before {
                vertical-align: baseline;
            }

            span {
                position: absolute;
                bottom: 175%;
                left: 50%;

                padding: 4px 6px 3px;
                white-space: nowrap;
                background-color: $color_c9;
                color: white;
                display: block;
                transform: translate(-50%, 0);
                transition: bottom 180ms ease;
            }

            &:hover {
                span {
                    bottom: 125%;
                }

            }

            span {
                font-size: 12px;
                line-height: 19px;
            }

            &:not(:hover) {
                span {
                    @include visuallyhidden() ;
                }
            }
        }
        
        .newsletter-share-link {
            display: none;
        }

        .share-artikel-actions {
            display: flex;
            justify-content: flex-end;

            a {
                float: left;

                &.watchlist-trigger {
                    &.checked, &.checked:hover {
                        color: $color_c4;
                    }
                }
            }
        }
    }

    [article-module=headline] {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 1/32 * 1em;
        margin-bottom: 10px;

        &:not(.small) {
            font-size: 38px;
            line-height: 50px;
            letter-spacing: 1/38 * 1em;
        }
    }

    [view=article] {
        font-size: 15px;
        line-height: 24px;
    }

    [article-module=subheadline] {
        margin-bottom: 13px;
        font-size: 16px;
        line-height: 22px;
    }

    [article-module=gallery], [article-module=video_embed], [article-module=object_information], [article-module=object_map], [article-module=downloads], [article-module=event], [article-module=book] {
        padding-top: 30px;
        padding-bottom: 40px;
    }

    [article-module=event] {
        padding-bottom: 30px;
    }

    [article-module=related_article], [article-module=related_objects], [article-module=related_tips] {
        padding-top: 30px;
        padding-bottom: 10px;
    }

    [article-module=architect_profile], [article-module=webtips], [article-module=downloads] {
        padding-top: 30px;
        padding-bottom: 30px;

        a {
            position: relative;

            &::before {
                content: '';
                display: block;
                width: 0;
                height: 2px;
                position: absolute;
                left: 0;
                bottom: -2px;
                z-index: 0;
                transition: width 180ms ease-out;
            }

            &.active, &:hover, &:focus {
                &::before {
                    width: 100%;
                }
            }
        }
    }

    [article-module=gallery], [article-module=video_embed] {

        padding-bottom: 17px;

        figcaption {
            padding: 17px 110px 0 50px;
        }
        .caption {
        }
        .copyrigth {
        }
    }

    [article-module=gallery] {
        padding-left: 0;
        padding-right: 0;

        h3 {
            padding: 0 40px;
        }

        .index {
            position: absolute;
            right: 15px;
            top: 36px;
        }

        .detail-image-wrapper {
            margin: 0 40px;
            height: 300px;
        }
    }

    [article-module=book] {

        position: relative;
        padding-bottom: 30px;

        @include clearfix();
    }

    [article-module=video_embed] {
        .video-wrapper {
            .video-link {
                &::after {
                    font-size: 96px;
                }

            }
        }
    }

    //[article-module=event] {
    //	.event-cover {
    //		display: inline;
    //		float: left;
    //		margin-right: 40px;
    //	}
    //}
}

@include bp-large () {

    [article-module] {
        margin-bottom: 30px;

        h3 {
            font-size: 24px;
            letter-spacing: 2/24 * 1em;
        }
    }
    
    [article-module=textblock] {
        h3 {
            font-size: 15px;
            line-height: 1.6;
            letter-spacing: initial;
            font-weight: 700;
            text-transform: none;
            margin-bottom: 0;
        }
    }
    
    [article-module=headline] {
        margin-bottom: 20px;
    }

    [view=topic] [article-module=headline]:not(.small) {
        font-size: 40px;
        line-height: 48px;
    }

    [article-module=subheadline] {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 13px;
    }

    [view=topic] [view=article]:not(.glossary-article) {

        [view=article] {
            font-size: 16px;
            line-height: 26px;
        }

        > .panel {
            right: 60px;
            width: $article-width-large;
        }

        .placeholder [article-module=headline] {
            font-size: 38px;
            line-height: 50px;
        }

        [article-module=gallery] {

            .detail-image-wrapper {
                height: 495px;
            }
        }

        [article-module=object_map] {
            .map {
                min-height: 140px;
            }
        }
    }
}

@include bp-xlarge () {
    [view=topic] [view=article]:not(.glossary-article) {

        > .panel {
            right: 45%;
            margin-right: -940px;
        }
    }
}