.detail-image {
	position: relative;
	
	.detail-image-wrapper {
		// position: relative;
		z-index: 1;
	}

	&::before, &::after  {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		z-index: 0;
		transition: opacity 200ms linear;
		/*
		width: 36px;
		height: 36px;
		border: 6px solid $color-c9;
		box-shadow: 0 0 4px -1px $color-c9, 0 0 4px -1px $color-c9 inset;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -18px;
		margin-top: -18px;
		
		opacity: 0;
		transition: opacity 160ms linear;
		*/
	}

	&:before {
		background-color: $color_c4;
		z-index: 1;
	}

	&::after {
		
		background: url('/assets/gfx/bg_dots_transparent.png') 2px 3px repeat transparent;
		z-index: 2;
	}

	
	&.lazy-image.loading {
		&::after, &::before {
			opacity: 1;
		}
	}
	
}