.close-page {
	position: absolute;
	right: 16px;
	top: 20px;
	width: 40px;
	height: 40px;
	z-index: 300;
	text-decoration: none;
	overflow: hidden;

    @media (min-width: 768px) {
        top: $menu-top-height-tablet + 20px;
    }

	transition: opacity 240ms linear;

	&::before {
		@include icon-styles();
		content: "\58";
		display: block;
		font-size: 36px;
		line-height: 40px;
		width: 40px;
		text-align: center;
		color: $color_c2;
	}

	.loading & {
		opacity: 0;
	}
}

@include bp-medium () {
	.close-page {
		position: fixed;
		right: 70px;
		top: $menu-top-height-desktop + 40px;
		width: 64px;
		height: 64px;

		&::before {
			font-size: 60px;
			line-height: 64px;
			width: 64px;
		}
	}

}