[view=search] {
	
	.filter-bar {
		position: relative;
		margin-bottom: 40px;
		z-index: 10;

		&::after {
			content: '';
			display: table;
			width: 100%;
			clear: both;
		}

		.dropdown {
			left: auto;
			top: auto;
			display: inline-block;
			margin-bottom: 0;
			margin-right: 40px;
			background-color: white;

			&:not(.has-selection) {
				.dropdown-head {
					background-color: $color-c2;

					.dropdown-toggle {
						color: $color-c1;
					}
				}
			}
		}
	}

	.message {
		margin-top: 20px;
	}
	#filter_section {
		margin-top: 20px;
	}

	.section-radio {
		margin-bottom: 10px;
		white-space: nowrap;

		a {
			text-decoration: none;
		}

		span {
			display: inline-block;
			width: 19px;
			height: 19px;
			margin-right: 10px;
			vertical-align: bottom;
			border: 2px solid $color-c7;
			border-radius: 100%;
			background-color: $color-c9;
		}

		&:hover span {
			border: 2px solid $color-c5;
		}

		.section-radio-bullet {
			border: 2px solid $color-c5;

			&:before {
    			content: "";
    			width: 7px;
    			height: 7px;
    			border-radius: 100%;
    			background-color: $color-c5;
    			display: inline-block;
    			margin-left: 4px;
    			margin-bottom: 2px;
			}
		}
	}
}

@include bp-medium {
	[view=search] {
		.filter-bar {
			margin-bottom: 70px;
			height: 45px;
		}

		#filter_section {
			margin-top: 0;
		}
	}
};