.text-page {
	color: white;
	background-color: black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding-top: 22px;
	padding-bottom: 95px;

    @media (min-width: 768px) {
        padding-top: $menu-top-height-tablet + 22px;
    }

	.page-title {
		font-size: 38px;
		line-height: 1;
		font-weight: 300;
        hyphens: auto;

		.count, .topic {
			// margin-left: 0.5em;
			color: $color-c6;
			display: inline-block;
		}
		.title {
			display: inline-block;
			margin-right: 0.5em;
		}
	}

	.page-subtitle {
		font-size: 16px;
		line-height: 26px;
		margin-bottom: 15px;
		max-width: 660px;
	}

	.page-description {
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 15px;
		max-width: 520px;
	}

	.page-body {
		p {
			font-size: 16px;
			line-height: 26px;
			max-width: 690px;
		}
	}
	
	hr {
		height: 1px;
		border: 0 none transparent;
		background: left top repeat transparent;
		background-image: $gfx-text-divider;
		margin: 23px 0 25px;
	}

	.animating & {
		position: fixed;
		overflow: hidden;
		min-height: 100%;
		width: 100%;
	}

	.page-body .list-footer {
		text-align: center;
		max-width: 100%;
	}

	.load-more-button {
		background-color: $color-c2;
		color: $color-c1;
		padding: 9px 12px 9px;
		text-transform: uppercase;

		display: inline-block;
		position: relative;

		font-family: $font-second;
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 1/32 * 1em;

		cursor: pointer;
	}
}

@include bp-max-medium {
	.text-page {
		min-height: 100%;

		.page-head, .page-body {
			padding-left: 20px;
			padding-right: 20px;
		}

		.page-title {
			margin-bottom: 13px;
		}
	}
}

/*
@include bp-small () {
	.text-page {
		padding-top: $menu-top-height-desktop + 35px;
	}
}
*/

@include bp-medium () {
	.text-page {
		padding: $menu-top-height-desktop 40px $menu-bottom-height-desktop + 60px;

		.wrapper {
			max-width: 1200px;
			margin: 0 auto;
			padding-top: 36px;
		}

		.page-title {
			font-size: 80px;
			line-height: 1;
			margin-bottom: 24px;
			text-indent: -3px;
		}

		.page-head {
			hr {
				margin-top: 60px;
			}
		}
	}
}


@include bp-large () {
	.text-page {
		padding: $menu-top-height-desktop 70px $menu-bottom-height-desktop + 60px;
	}
}