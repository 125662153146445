// ===================================================
// mixins
// ===================================================
@import "utils/functions";
@import "utils/mixins";
@import "utils/grid";


// ===================================================
// App Settings
// ===================================================
@import "app/variables";
@import "app/animations";


// ===================================================
// App Base Styles
// ===================================================
@import "base/typo";
@import "base/icons";
@import "base/symbols";
@import "base/global";


// ===================================================
// App Layout
// ===================================================
@import "layout/grid";
@import "layout/layout";


// ===================================================
// Plugins
// ===================================================
@import "plugins/slick/slick";
@import "plugins/slick/slick-theme";
@import "plugins/photoswipe/main";
@import "plugins/photoswipe/default-skin/default-skin";


// ===================================================
// Modules
// ===================================================
@import "modules/debug";
@import "modules/topic-dropdown";
@import "modules/teaser";
@import "modules/image";
@import "modules/search-field";
@import "modules/search-superheader-field";
@import "modules/dialog";
@import "modules/close-page";
@import "modules/eye-catcher";
@import "modules/welcome";
@import "modules/overscroll";
@import "modules/glossar-layer";
@import "modules/profession_poll";


// ===================================================
// App Components
// ===================================================
@import "components/menu";
@import "components/taxonomy-menu";
@import "components/topic-teaser";
@import "components/article";
@import "components/link-lists";
@import "components/partner-slider";
@import "components/pagination";
@import "components/share-block";
@import "components/tooltip";


// ===================================================
// App Pages
// ===================================================
@import "pages/text-page";
@import "pages/home";
@import "pages/topic";
@import "pages/topic-mobile";
@import "pages/glossary";
@import "pages/imprint";
@import "pages/history";
@import "pages/watchlist";
@import "pages/search";
@import "pages/newsletter";
@import "pages/exception";
@import "pages/dachkult";

// Fix
body {
    overflow: initial !important;
}