[view=exception] {
	color: $color-c2;

	.page-wrapper {
		padding: 0 20px;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		transform: translate(-50%,-50%);
		z-index: 10;
		text-align: center;
	}

	.page-title {
		font-family: $font-second;
		font-weight: 300;
		font-size: 60px;
		margin-bottom: 20px;
	}

	.page-subtitle {
		font-size: 16px;
		line-height: 21px;
	}

	.fullscreen-background {
		position: fixed;
		left: 0;
		top: 0;
		
		width: 100%;
		height: 100%;

			img {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				display: block;

				@media (min-aspect-ratio: 4/3) { height: auto; width: 100%; }
				@media (max-aspect-ratio: 4/3) { height: 100%; width: auto; }
			}
	}
}

#menu {
	.menu-mobile-bottom {
		a {
			float: right;
		}
	}
}

@include bp-medium () {
	[view=exception] {
		.page-wrapper {
			margin: 0 auto;
			max-width: 640px;
		}

		.page-title {
			font-size: 142px;
			margin-bottom: 30px;
		}

		.page-subtitle {
			font-size: 18px;
			line-height: 25px;
		}
	}
}