[view=topic] {

	.topic-background {
		display: none;
	}
}

.visual-timer {
    position: fixed;
	left: 0;
	bottom: 0;
	height: 3px;
	background-color: transparent;
	width: 100%;
	//z-index: 1000;
}
            
[article-module="headline"] {
    hyphens: auto;
}

@include bp-medium () {
	[view=topic] {
		&::before {
			content: '';
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			background: url('/assets/gfx/bg_dots_transparent.png') left top repeat transparent;
		}
		.topic-background {
			display: block;
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			overflow: hidden;
			z-index: 0;

			.detail-image {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;

				img {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					display: block;

					@media (min-aspect-ratio: 4/3) { height: auto; width: 100%; }
					@media (max-aspect-ratio: 4/3) { height: 100%; width: auto; }
				}
			}
		}

		[view=article], #taxonomy_menu, #topic_teaser_block {
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			z-index: 3;
            
			.panel {
				position: absolute;
			}

			&.active {
                position: relative;
				top: 0;
				bottom: auto;
				overflow-y: visible;

				&::after {
					content: '';
					display: table;
					width: 100%;
					position: relative;
				}
			}
		}

		#topic_teaser_block {
			z-index: 10;
            
            // overlay glossary layer
            &.active {
                z-index: 12;
            }
		}

		[view=article] {
			z-index: 5;
		}
	}

}